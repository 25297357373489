import { initializeApp } from 'firebase/app';

import { 
  getAnalytics, 
  logEvent,
  setUserProperties
} from "firebase/analytics";

import { 
  getAuth, 
  signInWithCustomToken 
} from 'firebase/auth';

import { 
  getFirestore, 
  collection, 
  doc,
  limit,
  query, 
  where, 
  orderBy,
  getDocs
} from "firebase/firestore";

import { config } from './config'

const firebaseConfigRinkeby = {
  apiKey: "AIzaSyBJRGGQs7QFWR-3FIxdfpmbzMTo_Zc5BfI",
  authDomain: "cryptocves-rinkeby.firebaseapp.com",
  projectId: "cryptocves-rinkeby",
  storageBucket: "cryptocves-rinkeby.appspot.com",
  messagingSenderId: "67655424372",
  appId: "1:67655424372:web:2f3a53f61119d78845f421",
  measurementId: "G-V8VF0S3HR8"
}

const firebaseConfigMainnet = {
  apiKey: "AIzaSyCShQmMdodMXV7vYiFTwtveI0pe8fy43Eo",
  authDomain: "cryptocves.firebaseapp.com",
  projectId: "cryptocves",
  storageBucket: "cryptocves.appspot.com",
  messagingSenderId: "638431635388",
  appId: "1:638431635388:web:8675e6ac6df50c67dfadb9",
  measurementId: "G-ZNGN0HLKQ7"
}


initializeApp(config.environment == 'mainnet' ? firebaseConfigMainnet : firebaseConfigRinkeby)
const db = getFirestore()
const auth = getAuth()
const analytics = getAnalytics()

// Logging 
const error = (text: string, data: any = {}) => logEvent(analytics, `error_${text}`, data)
const event = (text: string, data: any = {}) => logEvent(analytics, text, data)


export { 
  auth,
  signInWithCustomToken,
  
  db,
  collection,
  doc,
  limit,
  query, 
  where, 
  orderBy,
  getDocs,

  analytics,
  error,
  event,
  setUserProperties
}