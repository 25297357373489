import Web3 from "web3";
import Onboard from 'bnc-onboard'
const { config } = require('../assets/config');

export const connectWallet = async (success) => {
  const account = await getAccount()
  if(account != ''){return success()}
  try {
    const onboard = Onboard({
      dappId: config.blocknative.apiKey,
      networkId: getNetworkId(),
      darkMode: true,
      subscriptions: {
        wallet: wallet => {
          // const web3 = new Web3(wallet.provider)
          console.log(`Connected to ${wallet.name}`)
        }
      }
    })
    await onboard.walletSelect()
    await onboard.walletCheck()
    success()
  } catch(error){
    console.error(error)
  }
}

// export const connectToMetamask = async (success) => {
//   window.ethereum.request({ method: 'eth_requestAccounts' }).then(res => {
//     console.log(res)
//     success()
//   }).catch(error => {
//     console.log("ERROR: ",error)
//   })
// }

export const signNonce = async (nonce) => {
  const web3 = await getWeb3()
  const account = await getAccount()
  return web3.eth.personal.sign(nonce, account)
  .catch(error => {
    console.error(error)
    return error
  })
}

export const getAccount = async () => {
  try {
    const web3 = await getWeb3()
    const accounts = await web3.eth.getAccounts()
    const account = await accounts[0]
    return account ?? ''
  } catch(error){
    console.error(error)
    return ''
  }
}

export const getWeb3 = async () => {
    if (window.ethereum) {
        return new Web3(window.ethereum)
    } else if (window.web3) {
      return window.web3
    } 
    return undefined
}

export const getEthBalance = async () => {
  const address = await getAccount()
  if(address == ''){return undefined}
  const web3 = await getWeb3()
  const response = await web3.eth.getBalance(address)
  return web3.utils.fromWei(response, "ether")
}


export const getNetworkId = () => {
  switch(config.environment){
      case 'rinkeby': 
          return 421611 // Arbitrum Rinkeby
      case 'mainnet':
          return 42161 // Arbitrum Mainnet
      default: 
          return 666 // Local development
  }
}

export const getNetworkName = () => {
  switch(config.environment){
      case 'rinkeby': 
          return 'Arbitrum Rinkeby testnet'
      case 'mainnet':
          return 'Arbitrum Mainnet'
      default: 
          return 'Local development'
  }
}

export const onNetworkChange = (onChange) => {
  if(!window.ethereum) {return}
  window.ethereum.on('networkChanged', function(networkId){
      console.log('networkChanged',networkId);
      onChange(networkId)
    })
}

export const onAccountsChange = (onChange) => {
  if(!window.ethereum) {return}
  window.ethereum.on('accountsChanged', function (accounts) {
    console.log('accountsChanges',accounts);
    onChange(accounts)
  })
}

export const switchNetwork = async () => {
  const chainId = config.environment == 'mainnet' ? '0xA4B1' : '0x66EEB'
  const rpc = config.environment == 'mainnet' ? 'https://arb1.arbitrum.io/rpc' : 'https://rinkeby.arbitrum.io/rpc'
  const name = config.environment == 'mainnet' ? 'Arbitrum One' : 'Arbitrum Rinkeby'
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ 
        chainId: chainId
      }]
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: name,
              rpcUrls: [rpc]
            },
          ],
        });
      } catch (addError) {
        console.error('Couold not add chain: ',addError)
      }
    }
    console.error('Could not switch: ',switchError)
  }
  
}

export const selectedAddress = () => window?.ethereum?.selectedAddress ?? null
export const networkVersion = () => window?.ethereum?.networkVersion ?? null
export const getMetamask = () => window?.ethereum?.isMetaMask
export const isCorrectNetwork = () => getNetworkId().toString() === networkVersion()