
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { colors } from '../../../assets/config'
import './cardStyles.css'

const ScoreBadge = ({ score, severity }: { score: number, severity: string }) => {

    const getBackgroundColor = (severity: string) => {
        switch (severity) {
            case 'LOW':
                return colors.severity.low
            case 'MEDIUM':
                return colors.severity.medium
            case 'HIGH':
                return colors.severity.high
            case 'CRITICAL':
                return colors.severity.critical
            default:
                return colors.color3
        }
    }

    const useStyles = makeStyles({
        root: {
            borderRadius: 2.8,
            background: getBackgroundColor(severity),
            color: '#1F1F34',
            width: 28,
            height: 25,
            marginTop: 5,
            marginLeft: -2,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Kanit',
            fontSize: 12,
            verticalAlign: 'middle',
            lineHeight: 1.9
        }
    })
    const classes = useStyles();

    return  <div className={classes.root}>
                {score ? <div>{score}</div> : '?'}
            </div>
}

interface NftCardSmallPreviewProps {
    id: string
    tokenId?: number
    imageBase64?: string
    severity: string
    score: number
}

export default function NftCardSmallPreview({ id, tokenId, imageBase64, severity, score }: NftCardSmallPreviewProps){
    

const useStyles = makeStyles({
    root: {
        borderRadius: 7,
        background: colors.color2,
        position: 'absolute',
        width: '100%',
        height: '100%',
        fontWeight: 'bold',
        fontSize: 40,
        backfaceVisibility: 'hidden',
    },
    container: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        width: 156,
        height: 240,
        perspective: 1000,
        float: 'left'
    },
    card: {
        position: 'relative',
        width: '100%',
        height: '100%',
        transformStyle: 'preserve-3d',
        transformOrigin: 'center right',
        transition: 'transform 1s'
    },
    media: {
        margin: 'auto',
        marginTop: 25,
        marginBottom: 15,
        width: 110,
        height: 110
    },
    id: {
        color: colors.color8,
        fontWeight: 'normal',
        fontSize: 12,
        fontFamily: 'Kanit',
    },
    tokenId: {
        color: colors.color3,
        marginTop: -19,
        fontSize: 13,
        textAlign: 'left',
        fontWeight: 'normal',
        fontFamily: 'Biryani'
    },
    titleContainer: {
        float: 'left',
        marginRight: 5
    }
    })
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.card}>
            <Card className={classes.root}>
                <CardMedia
                    className={classes.media}
                    image={imageBase64 ?? ''}
                    title={id}
                />
                <CardContent>
                    <div>
                        <div className={classes.titleContainer}>
                            <ScoreBadge score={score} severity={severity} />
                        </div>
                        <div>
                            <p className={classes.id}>
                                {id}
                            </p>
                            <p className={classes.tokenId}>#{tokenId ?? ''}</p>
                        </div>
                    </div>
                </CardContent>
            </Card>
            </div>
        </div>
    )
}

