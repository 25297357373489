import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SellersInfo from './components/SellersInfo'
import { ScoreBadge } from '../ui/card/components'
import { NftType } from '../../interfaces'
import { colors } from '../../assets/config'

export const NFTheader = ({ token, price } : { token: NftType, price: string | undefined}) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                color: 'white'
            },
            score: {
                float: 'left',
                marginRight: 14,
                marginTop: 3,
            },
            id: {
                fontFamily: 'Kanit',
                fontSize: xs || sm ? 24 : 36,
                float: xs || sm ? 'none' : 'left',
                marginRight: xs || sm ? 0 : 10,
            },
            tokenId: {
                fontFamily: 'Kanit',
                fontWeight: 'bold',
                fontSize: xs || sm ? 16 : 26, 
                color: colors.color3,
                lineHeight: xs || sm ? 0.1 : 2.4,
            },
            description: {
                fontFamily: 'Biryani',
                fontSize: 16,
                marginTop: xs || sm ? 25 : 8,
            }
        }))
    const classes = useStyles();
    return token ? (
        <div className={classes.root}>
            <div className={classes.score}><ScoreBadge score={token.score} severity={token.severity}/></div>
            <div>
                <div className={classes.id}>{token.id}</div>
                <div className={classes.tokenId}>{token.tokenId ? `#${token.tokenId}` : 'Up for grabs'}</div>
            </div>
            <SellersInfo 
                price={price} 
                token={token}/>
            <div className={classes.description}>{token.description}</div>
        </div>
    ) : null
}