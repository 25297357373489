import moment from 'moment'
import SuperEvent from './SuperEvent'

export default class MintEvent extends SuperEvent {

    to!: string
    price!: string
    transactionHash!: string
    createdAt!: string

    static type: 'NftSold'

    constructor(event: any){
        super(event)
        this.to = event.returnValues.newOwner
        this.price = event.returnValues.price
        this.transactionHash = event.transactionHash
        this.createdAt = moment(new Date(event.createdAt.seconds*1000).toLocaleDateString()).format("DD MMM YYYY")
    }
}