import { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import { PageTitle, BackButton } from '../ui/components'
import NftCardGrid from '../ui/card/NftCardGrid'
import NFT from '../../services/NFT'
import { getLastUrlPath } from '../../hooks/miscHooks'
import { NftType } from '../../interfaces'
import { colors } from '../../assets/config'

export default function NFTtokens() {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

    const [tokens, setTokens] = useState<Array<NftType> | undefined>()
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            backButton: {
                marginTop: 30,
                marginLeft:  xs ? 0 : 30,
            }
    }))

    const classes = useStyles();

    const fetchTokens = async () => {
        const url = getLastUrlPath()
        let tokens = Array<NftType>()
        switch(url){
            case 'topsellers' :
                tokens = await NFT.getTopSellingTokens(75)
                break
            case 'sales' :
                tokens = await NFT.getTokensForSale(75)
                break
            case 'new' :
                tokens = await NFT.getNewlyMintedTokens(75)
                break;
            case 'unique' :
                tokens = await NFT.getUniqueTokens(75)
                break;
            default: 
                console.error("Invalid url path!")
        }
        setTokens(tokens)
    }

    useEffect(() => {
       fetchTokens()
    },[])

    return (
        <>
            <div className={classes.backButton}>
                <BackButton title="Back"/>
            </div>
            <PageTitle/>
            <Container>
                <NftCardGrid tokens={tokens}/>
            </Container>
        </>
   )
}