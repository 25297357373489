import { getWeb3 } from './Web3'
import { getAccount, getNetworkId } from './Web3'
const json = require('../contracts/Token.json');
const { Web3UnavailableError } = require('../assets/errors');
const convert = require('ether-converter')
const networkId = getNetworkId()

export default class Token {

    static contract: any

    constructor(){}

    static initContractIfNeeded = async () => {
        if(Token.contract){return}
        const web3 = await getWeb3()
        if(!web3){throw Web3UnavailableError}

        Token.contract = await new web3.eth.Contract(
            json.abi,
            json.networks[networkId].address
        )
    }

    static getBalance = async (address?: string) => {
        await Token.initContractIfNeeded()
        const _address = address ? address : await getAccount()
        const balance = await Token.contract.methods.balanceOf(_address).call()
        return convert(balance, 'wei').ether
      }
}