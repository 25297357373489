import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import InfoDialog from './dialogs/InfoDialog'
const { config } = require('../../assets/config.ts')
const { NftNotFoundError, NftProcessingError } = require('../../assets/errors')
const { colors } = require('../../assets/config')

interface PageHeaderSearchBarProps {
    title: string
    placeholder?: string
}

export default function PageHeaderSearchBar({ title, placeholder } : PageHeaderSearchBarProps){

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: 'white',
            height: 50,
            marginTop: 20,
            marginLeft: 20,
            marginRight: 15,
            marginBottom: xs ? 25 : 0,
        },
        title: {
            fontSize: 28,
            fontFamily: 'Kanit',
            float: 'left',
            marginBottom: xs ? 5 : 0,
        },
        searchBar: {
            borderRadius: 8,
            background: colors.color1,
            fontFamily: 'Biryani',
            float: 'right',
            width: xs ? '100%' : 250,
            border: `2px solid ${colors.color2}`,
            height: 40,
            color: colors.color4,
            fontSize: 14,
            paddingLeft: 20,
            paddingTop: 3
        },
        settingsButton: {
            color: colors.color4,
        },
        circularProgress: {
            color: colors.color3,
            marginTop: -3
        }
    }))
    const classes = useStyles();
    const [valid, setValid] = useState(false)
    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false)
    const [failed, setFailed] = useState('')
    const history = useHistory()

    const handleClick = async () => {
        // Check validitity
        setValid(true)
        setLoading(true)
        setFailed('')
        const response = await fetch(config.url.api+config.url.nftSearchResult+"?cve="+text.toUpperCase())
        const result = await response.json()
        setLoading(false)
        
        // Show error
        if(isError(result)){return}
        if(!result.cve){return}
        history.push({
            pathname: '/token/'+(result.cve.tokenId ? result.cve.tokenId : 'mint'),
                state: { token: result.cve }
        })
    }

    const isError = (result: any) => {
        if(result.error){
            switch(result.error){
                case NftNotFoundError :
                    setFailed('could not be found!')
                    console.error(result.error)
                    break;
                case NftProcessingError :
                    setFailed('is being processed by another user right now!')
                    console.error(result.error)
                    break;
                default :
                    setFailed('exhausted search')
            }
            return true
        }
        return false
    }

    const validate = (query: string) => {
        setText(query.toUpperCase())
        const isValid = text.startsWith('CVE-') && text.length > 9
        setValid(isValid)
    }

    return (
        <>
        <InfoDialog message="That CVE id does not exist. Example: CVE-2021-1337" open={failed == 'could not be found!'} onClose={()=> setFailed('')}/>
        <InfoDialog message="Sorry the search is exhausted, try again in minute..." open={failed == 'exhausted search'} onClose={()=> setFailed('')}/>
        <div className={`${classes.root} root2`}>
            <div className={`${classes.title} title2`}>{title}</div>
            <Input 
                onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault()
                      handleClick()
                    }
                  }}
                onChange={(e)=> validate(e.target.value)}
                className={`${classes.searchBar} searchBar2`} 
                placeholder={placeholder ?? ""}
                disableUnderline={true}
                endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClick}>
                            {loading ?  <CircularProgress size={18} className={classes.circularProgress}/> : <SearchIcon className={classes.settingsButton}/>}
                      </IconButton>
                    </InputAdornment>
                  }/>
        </div>
        </>
    )
}