import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getWeb3, connectWallet } from '../../services/Web3'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person'
import InfoDialog from '../ui/dialogs/InfoDialog'
import { Logo, HowToMintLink } from './components'
import { OutlinedColorButton } from '../ui/components'
import AccountContext from '../../contexts/AccountContext'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { getAccount, isCorrectNetwork, onNetworkChange, getNetworkName, onAccountsChange, switchNetwork } from '../../services/Web3'
import User from '../../services/User'
import ErrorDialog from '../../components/ui/dialogs/ErrorDialog'
import { auth, analytics, setUserProperties } from '../../assets/firebase'
import { colors } from '../../assets/config'

export default function TopNavbar() {

  const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        grow: {
          flexGrow: 1,
        },
        navbar : {
          background: colors.color2
        },
        wrongNetwork: {
          background: colors.orange,
          width: '100%',
          height: 28,
          textAlign: 'center',
          paddingRight: 20,
          fontFamily: 'Kanit',
          fontSize: 16,
          fontWeight: 'bold'
        },
        profileButton: {
          backgroundImage: 'none',
          boxShadow: 'none'
        }
      })
    )
  const classes = useStyles()
  const history = useHistory()
  const [errorCode, setErrorCode] = useState('')
  const [showWrongNetwork, setShowWrongNetwork] = useState(false)
  const [address, setAddress] = useState<string>('')
  const { user, setUser } = useContext(AccountContext);
  const [isMetamaskLoggedIn, setIsMetamaskLoggedIn] = useState<boolean | undefined>(undefined)
  const [isFirebaseLoggedIn, setIsFirebaseLoggedIn] = useState<boolean | undefined>(undefined)

  const handleClickSignIn = () => {
    connectWallet(() => signIn())
  }

  const handleClickSignOut = () => {
    User.signOut()
    window.location.reload()
  }

  const signIn = async () => {

    setIsMetamaskLoggedIn(true)
    setIsFirebaseLoggedIn(false)

    User.signIn((_user)=> {
      setIsFirebaseLoggedIn(true)
    }, (error)=> {
      setErrorCode(error)
    })
  }

  const checkWalletLoginStatus = async () => {
    const _account = await getAccount()
    setIsMetamaskLoggedIn(_account != '')

    // Notify user if wrong network is selected in wallet
    const web3 = await getWeb3()
    if(web3){
      setShowWrongNetwork(!isCorrectNetwork())
    }
  }

  const checkFirebaseLoginStatus = async () => {
    
    // Check if Firebase user is logged in
    auth.onAuthStateChanged(async user => {
      if(user){
        const _user = await User.getCurrentUser()
        const _account = await getAccount()
        if(_user?.account === _account){
          setUserProperties(analytics, { account: _user!.account })
          setUser!(_user!)
          setIsFirebaseLoggedIn(true)
          return
        }
      }
      setIsFirebaseLoggedIn(false)
      User.signOut()
    })
  } 

  const Buttons = () => {
    if(isMetamaskLoggedIn && isFirebaseLoggedIn) return <>{isCorrectNetwork() && <ProfileButton/>}&nbsp;&nbsp;<SignOutButton/></>
    if(!isMetamaskLoggedIn) return <ConnectWalletButton/>
    if(isMetamaskLoggedIn && !isFirebaseLoggedIn) return <SignInButton/>
    return null
  }

  const ConnectWalletButton = () => <OutlinedColorButton title="Connect wallet" handleClick={handleClickSignIn}/>
  const SignInButton = () => <OutlinedColorButton title="Sign in" handleClick={handleClickSignIn} disabled={!isCorrectNetwork()}/>
  const SignOutButton = () => <OutlinedColorButton title="Sign out" handleClick={handleClickSignOut}/>
  const ProfileButton = () => {
    return (
      <IconButton 
        className={classes.profileButton} 
        aria-label="Profile" 
        color="inherit"  
        onClick={()=> history.push(`/profile/${user?.account}`)}>
        <PersonIcon />
      </IconButton>
    )
  }

  const SwitchNetwork = () => {
    return (
      <div className={classes.wrongNetwork}>
      <Button 
        onClick={()=> switchNetwork()}
        size="small">Switch to {getNetworkName()}</Button>
    </div>
    )
  }

  useEffect(()=> {

    (async ()=>{
      await checkWalletLoginStatus()
      await checkFirebaseLoginStatus()
    })()

    // Network changes
    onNetworkChange((networkId: string)=> {
      setShowWrongNetwork(!isCorrectNetwork())
    })

    // Account changes
    onAccountsChange((accounts: [string]) => {
      console.log(accounts)
      checkWalletLoginStatus()
      checkFirebaseLoginStatus()
    })

  },[])

  return <>
    <ErrorDialog message="Something went wrong when signing message!" open={errorCode == 'invalid_signature'} onClose={()=> setErrorCode('')}/>
    <ErrorDialog message="Could not login!" open={errorCode == 'login_error'} onClose={()=> setErrorCode('')}/>
    <div className={classes.grow}>
      <InfoDialog message="Metamask cannot be found!" open={errorCode == 'metmask_error'} onClose={()=> setErrorCode('')}/>
      <AppBar className={classes.navbar} position="static">
        <Toolbar>
            <Typography variant="h6" noWrap>
            <Logo/>
            </Typography>
            <div className={classes.grow}/>
            <div>
              <HowToMintLink/>
              <Buttons/>
            </div>
        </Toolbar>
      </AppBar>
    </div>

    {showWrongNetwork && <SwitchNetwork/>}
  </>
}