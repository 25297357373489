import { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NftCardGrid from '../ui/card/NftCardGrid'
import NFT from '../../services/NFT'
import UserService from '../../services/User'
import User from './User'
import { BackButton } from '../ui/components'
import { AccountEventTable, ProfileButtons } from './components'
import { getAccount } from '../../services/Web3'
import { getLastUrlPath } from '../../hooks/miscHooks'
import { NftType, UserType } from '../../interfaces'
import { colors } from '../../assets/config'

export default function Profile() {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const [user, setUser] = useState<UserType | undefined>()
    const [tokens, setTokens] = useState<Array<NftType> | undefined>()
    const [account, setAccount] = useState<string>('')
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1,
                background: colors.color1,
                padding: xs ? 5 : 20
            },
            leftGrid: {
                flexBasis: xs || sm ? '0%' : '10%',
                paddingLeft: xs || sm || md ? 10 : 0,
                marginBottom: xs || sm || md ? 0 : 30,
            },
            rightGrid: {
                paddingLeft: xs || sm ? 10 : 90,
                paddingRight: xs || sm ? 10 : 90,
                marginBottom: 30,
            },
            bottomGrid: {
                paddingLeft: xs || sm || md ? 10 : 40,
                paddingRight: xs || sm || md ? 10 : 40,
            },
            backButton: {
                marginTop: 30,
                marginLeft:  xs ? 0 : 30,
            }
    }))

    const classes = useStyles();

    const fetchUserFromApi = async (_account: string) => {
        const _user = await UserService.getUser(_account)
        setUser(_user)
    }

    const fetchTokensFromApi = async (_account: string) => {
        const tokens = await NFT.getTokensForAccount(_account)
        setTokens(tokens)
    }

    const setAccountInfo = async (_account: string) => {
        // const __account = await getAccount()
        const __user = await UserService.getCurrentUser()
        setAccount(_account)
        setIsLoggedIn(_account == __user?.account)
    }

    useEffect(() => {
        const address = getLastUrlPath()
        setAccountInfo(address)
        fetchUserFromApi(address)
        fetchTokensFromApi(address)
    },[])

    return (
        <>
            <div className={classes.backButton}>
                <BackButton title="Back"/>
            </div>
            <Container className={classes.root}>
                <Grid container justifyContent="center">
                    <Grid className={classes.leftGrid} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <User user={user} isLoggedIn={isLoggedIn}/>
                        <ProfileButtons isLoggedIn={isLoggedIn} user={user}/>
                    </Grid>
                    <Grid className={classes.rightGrid} item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <AccountEventTable user={user} limit={isLoggedIn ? 5 : 4}/>
                    </Grid>
                    <Grid className={classes.bottomGrid} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <NftCardGrid title={isLoggedIn ? 'Your collection' : 'Collection'} tokens={tokens}/>
                    </Grid>
                </Grid>
            </Container>
        </>
   )
}