import { useState } from 'react'
import Marketplace from './components/marketplace/Marketplace'
import Profile from './components/profile/Profile'
import NFTtoken from './components/nft/NFTtoken'
import NFTtokens from './components/nft/NFTtokens'
import Tutorial from './components/tutorial/Tutorial'
// import { verifyContractAddressHook } from './hooks/contractHooks'
import TopNavbar from './components/top_navbar/TopNavbar'
import Footer from './components/ui/footer/Footer'
import AccountContext from './contexts/AccountContext'
import { UserType } from './interfaces'
import './assets/firebase'
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  
  // verifyContractAddressHook()

  const [user, setUser] = useState<UserType | undefined>(undefined)
  
  return (<AccountContext.Provider value={{ user, setUser }}>
          <Router>
            <TopNavbar/>
            <Switch>
              {/* <Route path="/marketplace"><Marketplace/></Route> */}
              <Route path="/token/:id" render={(props) => <NFTtoken {...props}/>}/>
              <Route path="/profile/:address"><Profile/></Route>
              <Route path="/topsellers"><NFTtokens/></Route>
              <Route path="/sales"><NFTtokens/></Route>
              <Route path="/new"><NFTtokens/></Route>
              <Route path="/unique"><NFTtokens/></Route>
              <Route path="/tutorial"><Tutorial/></Route>
              <Route path="/"><Marketplace/></Route>
            </Switch>
            <Footer/>
          </Router>
    </AccountContext.Provider>
  );
}

export default App;


