
import { useState, useEffect } from 'react'
import BlockchainEvent from '../../../services/BlockchainEvent'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { Eth } from '../../ui/components'
import { toEth } from '../../../hooks/miscHooks'
import { getShortWalletAddress, getEtherscanUrl } from '../../../hooks/miscHooks'
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../../assets/config'

interface SoldEventProps {
    type: string,
    to: string,
    price: string,
    transactionHash: string,
    createdAt: string,
}

interface PurchasedEventProps {
    type: string,
    from: string,
    to: string,
    price: string,
    transactionHash: string,
    createdAt: string,
}

interface MintedEventProps {
    type: string,
    to: string,
    cve: string,
    transactionHash: string,
    createdAt: string,
}

interface EventTableProps {
    title: string
    tokenId?: number,
    account?: string,
    limit: number
}

const EventTable = ({ title, tokenId, account, limit = 5 } : EventTableProps) => {
    const [events, setEvents] = useState<any | null>(null);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
                marginTop: 30,
                float: 'left'
            },
            title: {
                fontFamily: 'Biryani',
                fontSize: 13,
                color: colors.color4,
                marginBottom: 10
            },
            circularProgress: {
                color: colors.color3,
                marginLeft: 5,
                marginBottom: -3
            }
    }))
    const classes = useStyles();

    const Title = ({ title }: { title: string }) => {
        return <div className={classes.title}>{title}{!events ? <CircularProgress size={14} className={classes.circularProgress}/> : null}</div>
    }
    

    const fetchEvents = async () => {
        let events
        if(tokenId != undefined){
            events = await BlockchainEvent.getAllByNFT(tokenId)
        } else if(account != undefined){
            events = await BlockchainEvent.getAllByAccount(account, limit)
        }
        setEvents(events)
    }

    useEffect(() => {
        fetchEvents()
    },[])

    return events != null && events.length > 0 ? (
        <div className={classes.root}>
            <Title title={title}/>
            {events ? events.map((event:any, i:number) => {
                console.log(event.type)
                switch(event.type){
                    case 'NftMinted' :
                        return <MintedEventRow key={i} event={event} account={account}/>
                    case 'NftSold' :
                        return <SoldEventRow key={i} event={event}/>
                    case 'NftPurchased' :
                        return <PurchasedEventRow key={i} event={event}/>
                    default: 
                        console.log("Unhandled event: ",event)
                        return null
                }
            }) : null }
        </div>
    ) : null
}

const SoldEventRow = ({ event } : { event: SoldEventProps } ) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            background: colors.color2,
            borderRadius: 16,
            height: 75,
            marginBottom: 10,
            color: 'white',
            textAlign: 'left',
            cursor: 'pointer',
            transition: 'transform .2s',
            '&:hover': {
                transform: 'scale(0.98)'
            },
        },
        first: {
            float: 'left',
            width: xs ? '40%' : '25%',
            height: '100%',
            paddingLeft: 20
        },
        second: {
            float: 'left',
            width: xs ? '60%' : '30%',
            height: '100%',
        },
        third: {
            float: 'left',
            width: xs ? '0%' : '45%',
            height: '100%',
            display: xs ? 'none' : 'true'
        },
        title: {
            fontFamily: 'Biryani',
            fontSize: 13,
            color: colors.color3,
            paddingTop: 17
        },
        subtitle: {
            fontFamily: 'Biryani',
            fontSize: 18,
            color: 'white',
            fontWeight: 'bold',
            paddingBottom: 5
        },
        button: {
            float: 'right',
            marginTop: -20,
            marginRight: 20,
            color: colors.color3
        }
    }))
    const classes = useStyles();

    const Price = () => {
        if(event.price){
            return <><Eth/>&nbsp;{toEth(event.price)}</>
        }
        return <div>...</div>
    }

    return (
        <div className={classes.root} onClick={()=> window.open(getEtherscanUrl()+'tx/'+event.transactionHash, '_blank')}>
            <div className={classes.first}>
                <div className={classes.title}>Sold for</div>
                <div className={classes.subtitle}><Price/></div>
            </div>
            <div className={classes.second}>
                <div className={classes.title}>Sold to</div>
                <div className={classes.subtitle} style={{float: 'left'}}>{getShortWalletAddress(event.to)}</div>
                {xs ? <div className={classes.button}><OpenInNew/></div> : null}
            </div>
            <div className={classes.third}>
                <div className={classes.title}>View transaction</div>
                <div className={classes.subtitle} style={{float: 'left'}}>{event.createdAt}</div>
                <div className={classes.button}><OpenInNew/></div>
            </div>
           
        </div>
    )
}


const PurchasedEventRow = ({ event } : { event: PurchasedEventProps } ) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            background: colors.color2,
            borderRadius: 16,
            height: 75,
            marginBottom: 10,
            color: 'white',
            textAlign: 'left',
            cursor: 'pointer',
            transition: 'transform .2s',
            '&:hover': {
                transform: 'scale(0.98)'
            },
        },
        first: {
            float: 'left',
            width: xs ? '40%' : '25%',
            height: '100%',
            paddingLeft: 20
        },
        second: {
            float: 'left',
            width: xs ? '60%' : '30%',
            height: '100%',
        },
        third: {
            float: 'left',
            width: xs ? '0%' : '45%',
            height: '100%',
            display: xs ? 'none' : 'true'
        },
        title: {
            fontFamily: 'Biryani',
            fontSize: 13,
            color: colors.color3,
            paddingTop: 17
        },
        subtitle: {
            fontFamily: 'Biryani',
            fontSize: 18,
            color: 'white',
            fontWeight: 'bold',
            paddingBottom: 5
        },
        button: {
            float: 'right',
            marginTop: -20,
            marginRight: 20,
            color: colors.color3
        }
    }))
    const classes = useStyles();

    const Price = () => {
        if(event.price){
            return <><Eth/>&nbsp;{toEth(event.price)}</>
        }
        return <div>...</div>
    }

    return (
        <div className={classes.root} onClick={()=> window.open(getEtherscanUrl()+'tx/'+event.transactionHash, '_blank')}>
            <div className={classes.first}>
                <div className={classes.title}>Purchased</div>
                <div className={classes.subtitle}><Price/></div>
            </div>
            <div className={classes.second}>
                <div className={classes.title}>Purchased from</div>
                <div className={classes.subtitle} style={{float: 'left'}}>{getShortWalletAddress(event.from)}</div>
                {xs ? <div className={classes.button}><OpenInNew/></div> : null}
            </div>
            <div className={classes.third}>
                <div className={classes.title}>View transaction</div>
                <div className={classes.subtitle} style={{float: 'left'}}>{event.createdAt}</div>
                <div className={classes.button}><OpenInNew/></div>
            </div>
           
        </div>
    )
}


const MintedEventRow = ({ event, account } : { event : MintedEventProps, account?: string } ) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            background: colors.color2,
            borderRadius: 16,
            height: 75,
            marginBottom: 10,
            color: 'white',
            textAlign: 'left',
            cursor: 'pointer',
            transition: 'transform .2s',
            '&:hover': {
                transform: 'scale(0.98)'
            },
        },
        first: {
            float: 'left',
            width: xs ? '40%' : '25%',
            height: '100%',
            paddingLeft: 20
        },
        second: {
            float: 'left',
            width: xs ? '60%' : '30%',
            height: '100%',
        },
        third: {
            float: 'left',
            width: xs ? '0%' : '45%',
            height: '100%',
            display: xs ? 'none' : 'true'
        },
        title: {
            fontFamily: 'Biryani',
            fontSize: 13,
            color: colors.color3,
            paddingTop: 17
        },
        subtitle: {
            fontFamily: 'Biryani',
            fontSize: 18,
            color: 'white',
            fontWeight: 'bold',
            paddingBottom: 5
        },
        button: {
            float: 'right',
            marginTop: -20,
            marginRight: 20,
            color: colors.color3
        }
    }))
    const classes = useStyles();
    const title = account ? 'NFT' : 'Minted by'
    const subtitle = account ? event.cve : getShortWalletAddress(event.to)

    return (
        <div className={classes.root} onClick={()=> window.open(getEtherscanUrl()+'tx/'+event.transactionHash, '_blank')}>
            <div className={classes.first}>
                <div className={classes.title}>Event</div>
                <div className={classes.subtitle}>Minted</div>
            </div>
            <div className={classes.second}>
                <div className={classes.title}>{title}</div>
                <div className={classes.subtitle} style={{float: 'left'}}>{subtitle}</div>
                {xs ? <div className={classes.button}><OpenInNew/></div> : null}
            </div>
            <div className={classes.third}>
                <div className={classes.title}>View transaction</div>
                <div className={classes.subtitle} style={{float: 'left'}}>{event.createdAt}</div>
                <div className={classes.button}><OpenInNew/></div>
            </div>
           
        </div>
    )
}

export default EventTable;