import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { NftCardSmall, NftCardSmallPlaceholder } from './NftCardSmall'
import { NftType } from '../../../interfaces'
const { colors } = require('../../../assets/config')

interface NftCardGridProps {
    tokens: Array<NftType> | undefined
    title?: string
    viewAllPath?: string
}

export default function NftCardGrid({ tokens, title = '', viewAllPath = '' } : NftCardGridProps){

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            textAlign: 'center',
        },
        cardContainer: {
            width: '100%',
            marginTop: 20,
        },
        placeholderContainer: {
            float: 'left',
        }
    }))
    const classes = useStyles()
    
    const NftCards = () => {
        const showPlaceholders = (tokens == null)

        const NftCardsSmallPlaceholders = () => {
            return <>{[0,1,2,3,4,5].map((token, i) => (
                <Grid key={i} item>
                    <NftCardSmallPlaceholder index={i} key={i}/>
                </Grid>
            ))}</>
        }

        const NftCardsSmall = () => {
            return <>{tokens!.map((token, i) => (
                <Grid key={i} item>
                    <NftCardSmall token={token}/>
                </Grid>
            ))}</>
        }

        return (
            <Grid container className={classes.root}>
                <Grid item>
                    <Grid container justifyContent={xs ? 'center' : undefined} spacing={2}>
                        {showPlaceholders ? <NftCardsSmallPlaceholders/> : <NftCardsSmall/>}
                    </Grid>
                </Grid>
            </Grid>)
        }
        
        return tokens != null && tokens.length > 0 ? (
            <div className={classes.root}>
                <NftRowTitle viewAllPath={viewAllPath} title={title}/>
                <br/>
                <div className={classes.cardContainer}>
                    <NftCards/>
                </div>
            </div>
    ) : null
}

const NftRowTitle = ({ title, viewAllPath } : { title : string, viewAllPath: string}) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
               color: 'white',
               fontSize: 20,
               fontFamily: 'Kanit',
               marginLeft: xs ? 12 : 0
            },
            title: {
                float: 'left',
                marginTop: 5
            }
    }))
    const classes = useStyles()
    return title != '' ? (
        <div className={classes.root}>
            <div className={classes.title}>{title}</div>
            <ViewAllButton path={viewAllPath}/>
        </div>
    ) : null
}

interface ViewAllProps {
    path: string
}

const ViewAllButton = ({ path } : ViewAllProps ) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                float: 'right',
                marginRight: 15
            },
            button: {
                height: 40,
                paddingRight: -20,
                borderColor: colors.color2,
                borderWidth: 2,
                borderRadius: 10,
                float: 'left'
            },
            icon: {
                marginLeft: 10,
                fontSize: '18px',
                color: colors.color4,
                marginRight: -10,
            },
            title: {
                fontSize: '14px',
                fontFamily: 'Biryani',
                fontStyle: 'normal',
                textTransform: 'none',
                color: colors.color4,
                marginTop: 5,
                marginLeft: -5,

            }
    }))
    const history = useHistory()
    const classes = useStyles()
    return path != '' ? (
        <div className={classes.root}>
        <Button 
        onClick={()=> history.push(path)}
        className={classes.button} 
        size="large"
        variant="outlined" 
        color="primary">
            <div className={classes.title}>View more</div>
            <ArrowForwardIos className={classes.icon} />
        </Button>
        </div>
    ) : null
}