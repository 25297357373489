import { config } from '../assets/config'
import { NftType } from '../interfaces'

// Card styles
import '../components/ui/card/styles/leet.scss'

const convert = require('ether-converter')

export const getShortWalletAddress = (address: string) => {
    if(!address){return ''}
    const length = address.length
    return address.substring(0,5)+'...'+address.substring(length-4, length)
}

export const getShortWalletAddressForProfile = (address: string) => {
    if(!address){return ''}
    const length = address.length
    return address.substring(0,4)+'...'+address.substring(length-4, length)
}

export const getEtherscanUrl = () => {
    if(config.environment == 'mainnet'){
        return config.link.ehterscan.mainnet
    } else {
        return config.link.ehterscan.rinkeby
    }
}

export const toEth = (wei: string) => convert(wei, 'wei').ether ?? '0'

export const toWei = (eth: string) => convert(eth, 'ether').wei

export const getLastUrlPath = () => {
    const url = window.location.pathname
    return url.substring(url.lastIndexOf('/')+1)
}

export const getTagsForToken = (token: NftType) => {
    const tags = []

    // First
    if(token.tokenId <= 100){
        tags.push('⭐ '+token.tokenId)
    }
    if(token.id.includes('1337')){
        tags.push('😎 leet')
    }
    return tags
}

export const getCardStyles = (token: NftType) => {
    var styles = ''
    if(token.id.includes('-1337')){
        styles += ' leet'
    }
    return styles
}