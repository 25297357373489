import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom'
import { config, colors } from '../../assets/config'

export const Logo = () => {
  const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
  const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
  const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
  const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
  const xl = useMediaQuery('min-width: 1920px');

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        cursor: 'pointer',
      },
      logo: {
        height: 35,
        float: 'left',
        marginRight: 10
      },
      text: {
        fontFamily: 'Kanit',
        fontSize: 24,
        fontWeight: 700,
        fontStyle: 'normal',
        marginRight: 15,
        float: 'left'
      },
      testnet: {
        float: 'left',
        fontSize: 18,
        lineHeight: 2.1,
        fontFamily: 'Kanit',
        fontStyle: 'normal',
        color: colors.red
      }
    }))
  const classes = useStyles()
  const history = useHistory()

  const Testnet = () => {
    return config.environment == 'rinkeby' ? <div className={classes.testnet}>(rinkeby testnet)</div> : null
  }

  return (
    <div className={classes.root} onClick={()=> history.push('/')}>
       <img className={classes.logo} src="/images/logo.png"/>
      {sm || md || lg || xl ? <div className={classes.text}>
        CRYPTOCVES
      </div> : null}
      <Testnet/>
    </div>
  )
}

export const HowToMintLink = () => {

  const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
  const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');

  const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          display: xs || sm ? 'none' : 'true',
          marginRight: 50,
          marginTop: 10,
          color: colors.color4,
          float: 'left',
          cursor: 'pointer'
        }
      })
    )
  const classes = useStyles()
  const history = useHistory()

  return <div className={classes.root} onClick={()=> history.push('/tutorial')}>How to mint</div>
}