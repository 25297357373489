import { useState, ReactNode } from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { ScoreBadge } from '../card/components'
import { colors } from '../../../assets/config'
import { NftType } from '../../../interfaces'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      background: colors.color2,
      fontFamily: 'Kanit',
      fontSize: 24,
      color: 'white'
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: ReactNode;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      <div><ErrorOutlinedIcon/> Error</div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    overflow: 'none',
    background: colors.color2,
    fontFamily: 'Kanit',
    fontSize: 24,
    color: 'white'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: colors.color2,
    textAlign: 'center'
  },
}))(MuiDialogActions);

interface DialogProps {
  message: string,
  open?: boolean,
  onClose?: () => void
}

export default function InfoDialog({ message, open = true, onClose } : DialogProps) {

  const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
  const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: 'white',
        width: xs || sm ? '100%' : 350,
        borderRadius: 16,
      },
      message: {
        fontFamily: 'Kanit',
        fontSize: 18,
      },
  }))

  const classes = useStyles();


const CancelButton = () => {
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px solid ${colors.color4}`,
      color: colors.color4,
      boxShadow: 'none',
      borderRadius: 8,
      textTransform: 'none',
      fontFamily: 'Biryani',
      paddingLeft: 15,
      paddingRight: 15,
    }
  }))
  const classes = useStyles();
  return (
      <Button 
          className={classes.root} 
          onClick={onClose}
          size="medium">
          OK
      </Button>
  )
}

  return open ? (
    <Dialog 
      classes={{paper: classes.root}}
      onClose={onClose} 
      aria-labelledby="mint-dialog-title" 
      open={true}>
    {/* <DialogTitle id="mint-dialog-title">
    </DialogTitle> */}
    <DialogContent>
      <div className={classes.message}>{message}</div>
    </DialogContent>
    <DialogActions>
      <CancelButton/>
    </DialogActions>
  </Dialog>
  ) : null
}