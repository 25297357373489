
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { getShortWalletAddress } from '../../../hooks/miscHooks';
import { Eth } from '../../ui/components'
import { colors } from '../../../assets/config'
import { NftType } from '../../../interfaces';
import { useHistory } from 'react-router-dom';
const convert = require('ether-converter')

export default function SellersInfo({ token, price } : { token: NftType, price: string | undefined }){

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
                marginTop: xs || sm ? 30 : 10,
                marginBottom: 30,
            },
            container: {
                width: '100%',
                height: 73,
            }
    }))

    const classes = useStyles();

    return token.owner ? (
            <div className={classes.root}>
                <Title title={token.price? 'Sellers info' : 'Owner info'}/>
                <div className={classes.container}>
                    <Price wei={price ?? '0'}/>
                    <Owner owner={token.owner}/>
                </div>
            </div>
    ) : null
}

const Title = ({ title }: { title: string }) => {
    
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: { 
                fontFamily: 'Biryani',
                fontSize: 13,
                color: colors.color4,
                marginBottom: 10
        }
    }))
    const classes = useStyles()
    return <p className={classes.root}>{title}</p>
}


const Price = ({ wei }: { wei: string }) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: 130,
                background: colors.color2,
                borderRadius: 16,
                height: 73,
                marginBottom: 10,
                marginRight: xs ? 10 : 30,
                color: 'white',
                textAlign: 'left',
                float: 'left'
            },
            container: {
                float: 'left',
                paddingLeft: 20
            },
            title: {
                fontFamily: 'Biryani',
                fontSize: 13,
                color: colors.color3,
                paddingTop: 17
            },
            subtitle: {
                fontFamily: 'Biryani',
                fontSize: 18,
                color: 'white',
                fontWeight: 'bold',
                paddingBottom: 5
            },
        }
    ))

    const classes = useStyles();
    return wei != '0' ? (
        <div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.title}>Current price</div>
                    <div className={classes.subtitle}><Eth/>&nbsp;{convert(wei, 'wei').ether}</div>
                </div>
            </div>
    ) : null
}


const Owner = ({ owner }: { owner: string }) => {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                minWidth: 150,
                background: colors.color2,
                borderRadius: 16,
                height: 73,
                marginBottom: 10,
                color: 'white',
                textAlign: 'left',
                float: 'left',
                cursor: 'pointer',
                transition: 'transform .2s',
                '&:hover': {
                    transform: 'scale(0.98)'
                },
            },
            container: {
                float: 'left',
                paddingLeft: 20,
                paddingRight: 20,
            },
            title: {
                fontFamily: 'Biryani',
                fontSize: 13,
                color: colors.color3,
                paddingTop: 17
            },
            subtitle: {
                fontFamily: 'Biryani',
                fontSize: 18,
                color: 'white',
                fontWeight: 'bold',
                paddingBottom: 5
            },
        }
    ))

    const classes = useStyles();
    const history = useHistory()

    return (
        <div className={classes.root} onClick={()=> history.push("/profile/"+owner)}>
                <div className={classes.container}>
                    <div className={classes.title}>Current owner</div>
                    <div className={classes.subtitle}>{getShortWalletAddress(owner)}</div>
                </div>
            </div>
    )
}
