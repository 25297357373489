import { useState, ReactNode, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { NftCardSmall } from '../card/NftCardSmall'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Marketplace from '../../../services/Marketplace'
import NFT from '../../../services/NFT'
import { getAccount } from '../../../services/Web3'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toWei } from '../../../hooks/miscHooks'
import { colors } from '../../../assets/config'
import { NftType } from '../../../interfaces'
import { toEth } from '../../../hooks/miscHooks'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      background: colors.color2,
      fontFamily: 'Kanit',
      fontSize: 24,
      color: 'white',
      textAlign: 'center'
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: ReactNode;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      <div>You are about to sell</div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    overflow: 'none',
    background: colors.color2,
    fontFamily: 'Kanit',
    fontSize: 24,
    color: 'white',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(2),
    background: colors.color2,
    textAlign: 'center'
  },
}))(MuiDialogActions);

interface SaleDialogProps {
  token: NftType, 
  open: boolean,
  onConfirm: () => void,
  onClose: () => void
}

export default function SaleDialog({ token, open, onConfirm, onClose } : SaleDialogProps) {

  const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
  const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');

  const [confirmButtonTitle, setConfirmButtonTitle] = useState('Approve')
  const [price, setPrice] = useState('')
  const [isApproved, setApproved] = useState(false)
  const [isFinishing, setFinishing] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [validPrice, setValidPrice] = useState(false)

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: 'white',
        minWidth: xs ? '90%' : 400,
        marginLeft: xs ? '5%' : 0,
        marginRight: xs ? '5%' : 0,
        borderRadius: 16
      },
  }))

  const classes = useStyles();
  const [mintFee, setMintFee] = useState<string | null>(null)
  const [marketplaceFee, setMarketplaceFee] = useState<number | null>(null)

  const fetchFeeFromBlockchain = async () => {
    const _mintFee = await Marketplace.getMintFee()
    setMintFee(toEth(_mintFee))
    const _marketplaceFee = await Marketplace.getMarketplaceFee()
    setMarketplaceFee(100/parseInt(_marketplaceFee))
  }

  const checkApproved = async () => {
    const _isApproved = await NFT.isMarketplaceApproved(token.tokenId)
    setApproved(_isApproved)
    setConfirmButtonTitle(_isApproved ? 'Finish' : 'Approve')
    setLoading(false)
  }

  useEffect(() => {
    checkApproved()
    fetchFeeFromBlockchain()
  },[])

  const Card = () => {

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginTop: 5,
        width: '100%',
        height: 250,
        textAlign: 'center',
        pointerEvents: 'none',
        marginBottom: 10
      },
      cardContainer: {
        filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))',
        margin: 'auto',
        borderRadius: 5,
        width: 156,
        height: 240,
      }
    }))

    const classes = useStyles();

    return (
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          <NftCardSmall token={token}/>
        </div>
      </div>
    )
  }

  const PriceInput = () => {

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%'
      },
      searchbar: {
        borderRadius: 8,
        background: colors.color1,
        fontFamily: 'Biryani',
        width: '100%',
        border: `2px solid ${colors.color2}`,
        height: 40,
        color: colors.color4,
        fontSize: 14, 
        paddingLeft: 20,
        paddingTop: 3,
      },
      title: {
        fontFamily: 'Biryani',
        fontSize: 13,
        color: colors.color3,
        marginTop: 20
      },
      button: {
        color: colors.color4,
        marginTop: -3,
        marginRight: -5,
      }
    }))
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <div className={classes.title}>Price</div>
        <Input 
          value={price}
          onKeyDown={(e) => {if(e.code === "Enter") {e.preventDefault()}}}
             onChange={(e)=> {
              const _valid = e.target.value.match(/^[0-9]*\.?[0-9]*$/) != null
              setValidPrice(_valid && parseFloat(e.target.value) >= parseFloat(mintFee ?? '0') && parseFloat(e.target.value) <= 1000)
              setPrice(_valid ? e.target.value : '')
          }}
          autoFocus
          className={classes.searchbar} 
          placeholder={mintFee ? `Minimum ${mintFee} Eth` : '...'}
          disableUnderline={true}
          endAdornment={
              price != '' ? <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{
                    setPrice('')
                    setValidPrice(false)
                    }}>
                    <CloseIcon className={classes.button}/>
                  </IconButton>
              </InputAdornment> : undefined
            }/>
          </div>
    )
  }

  const Fee = () => {
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        fontFamily: 'Biryani',
        fontSize: 13,
        color: colors.color3,
        marginTop: 20,
      }
    }))
    const classes = useStyles();
    const fee = marketplaceFee ? `${marketplaceFee}% of price when sold` : '...'
    return (
      <>
      <div className={classes.root} style={{float: 'left'}}>Fee</div>
      <div className={classes.root} style={{float: 'right'}}><strong>{fee}</strong></div>
      </>
    )
  }

  const CancelButton = () => {
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        border: `2px solid ${colors.color4}`,
        color: colors.color4,
        boxShadow: 'none',
        borderRadius: 8,
        textTransform: 'none',
        fontFamily: 'Biryani',
        paddingLeft: 15,
        paddingRight: 15,
      }
    }))
    const classes = useStyles();
    return (
        <Button 
            className={classes.root} 
            onClick={onClose}
            size="medium">
            Cancel
        </Button>
    )
}

// const RevokeButton = () => {
//   const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       border: `2px solid #FF003C`,
//       color: '#FF003C',
//       boxShadow: 'none',
//       borderRadius: 8,
//       textTransform: 'none',
//       fontFamily: 'Biryani',
//       paddingLeft: 15,
//       paddingRight: 15,
//     }
//   }))
//   const classes = useStyles();

//   const handleClick = async () => {
//     const _account = await getAccount()
//     const success = await NFT.approve(_account, token.tokenId)
//     console.log("success: ",success)
//     setApproved(!success)
//   }

//   return isApproved ? (
//       <Button 
//           className={classes.root} 
//           onClick={handleClick}
//           size="medium">
//           Revoke
//       </Button>
//   ) : null
// }

const ConfirmButton = () => {

  const isStepOne = !isApproved && !isLoading
  const isStepTwo = isApproved && validPrice && !isLoading

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px solid ${colors.theme}`,
      color: colors.theme,
      boxShadow: 'bold',
      borderRadius: 8,
      textTransform: 'none',
      fontFamily: 'Biryani',
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 15,
      opacity: isStepOne || isStepTwo ? 1 : 0.3,
      pointerEvents: isStepOne || isStepTwo ? 'auto' : 'none',
    },
    circularProgress: {
      color: colors.theme,
      marginTop: -3,
      marginRight: 5
    }
  }))
  const classes = useStyles();

  const handleOnClick = () => {
    if(!isApproved){
      return onClickApprove()
    }
    onClickSell()
  }

  return (
      <Button 
          className={classes.root} 
          size="medium"
          onClick={handleOnClick}>
          {isLoading && <CircularProgress size={18} className={classes.circularProgress}/>}
          {confirmButtonTitle}
      </Button>
  )
}

const NotApproved = () => {

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
     fontSize: 13,
     textAlign: 'center',
     paddingTop: 20,
     maxWidth: 400,
     paddingLeft: 20,
     paddingRight: 20,
    }
  }))
  const classes = useStyles();

  return <p className={classes.root}>By listing this item on the marketplace you are allowing us to handle your token. You may revoke this at any time.</p>
}

const Approved = () => {

  return(
    <>
      <PriceInput/>
      <Fee/>
    </>
  )
}


const onClickApprove = async () => {
  setLoading(true)
  setConfirmButtonTitle('Approving')
  const response = await Marketplace.approveNFT(token.tokenId, (success) => {
    if(!success){
      setLoading(false)
      setApproved(false)
      setConfirmButtonTitle('Try again')
      return console.error('Could not approve!')
    }
    setLoading(false)
    setApproved(true)
    setConfirmButtonTitle('Finish')
  })

  // Error handling
  if(response && response.error) {
    setLoading(false)
    switch(response.error){
        case 'USER_REJECTED' :
            console.log("User rejected!")
            break
        default: 
            // setErrorCode('withdraw_error')
    }
  }
}

const onClickSell = async () => {
  if(price == ''){return}
  setLoading(true)
  setConfirmButtonTitle('Finishing')
  setFinishing(true)
  const response = await Marketplace.offerNFT(token.tokenId, toWei(price), (success) => {
    if(!success){
      setLoading(false)
      setApproved(false)
      setConfirmButtonTitle('Try again')
      return console.error('Could not approve!')
    }
    setLoading(false)
    setApproved(true)
    onConfirm()
  })

  // Error handling
  if(response && response.error) {
    setLoading(false)
    switch(response.error){
        case 'USER_REJECTED' :
            console.log("User rejected!")
            break
        default: 
            // setErrorCode('withdraw_error')
    }
  }
}


  return (
    <Dialog 
      classes={{paper: classes.root}}
      onClose={onClose} 
      aria-labelledby="mint-dialog-title" 
      open={open}>
    <DialogTitle id="mint-dialog-title">
      Modal title
    </DialogTitle>
    <DialogContent>
      {/* <Header/> */}
      <Card/>
      {isApproved ? <Approved/> : <NotApproved/>}
    </DialogContent>
    <DialogActions>
      <CancelButton/>
      {/* <RevokeButton/> */}
      <ConfirmButton/>
    </DialogActions>
  </Dialog>
  );
}
