import SuperEvent from '../models/SuperEvent'
import MintEvent from '../models/MintEvent'
import SoldEvent from '../models/SoldEvent'
import PurchasedEvent from '../models/PurchasedEvent'
const { db, collection, query, limit, orderBy, where, getDocs } = require('../assets/firebase')

export default class BlockchainEvent {

    static doc: string = 'Events'

    constructor(){}

    static getAllByNFT = async (tokenId: number, _limit: number = 10) => {
        var docs = await collection(db, BlockchainEvent.doc)
        const q = query(docs, where('tokenId','==', tokenId), orderBy('createdAt','desc'), limit(_limit))
        const querySnapshot = await getDocs(q)
        
        const events = Array<SuperEvent>()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => {
            const event = doc.data()
            // console.log(event)
            switch(event.event){
                case 'NftMinted':
                    events.push(new MintEvent(event))
                    break;
                case 'NftSold':
                    events.push(new SoldEvent(event))
                    break;
                case 'NftPurchased':
                    events.push(new PurchasedEvent(event))
                    break;
                case 'NftOffer':
                    break; // Don't show this event
                default:
                    console.log("Unknown event: ",event)
            }
        })
        return events
    }

    static getAllByAccount = async (account: string, _limit: number = 5) => {
        var docs = await collection(db, BlockchainEvent.doc)
        const q = query(docs, where('account','==', account), orderBy('createdAt','desc'), limit(_limit))
        const querySnapshot = await getDocs(q)
        
        const events = Array<SuperEvent>()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => {
            const event = doc.data()
            // console.log(event)
            switch(event.event){
                case 'NftMinted':
                    events.push(new MintEvent(event))
                    break;
                case 'NftSold':
                    events.push(new SoldEvent(event))
                    break;
                case 'NftPurchased':
                    events.push(new PurchasedEvent(event))
                    break;
                case 'NftOffer':
                    break; // Don't show this event
                default:
                    console.log("Unknown event: ",event)
            }
        })
        return events
    }
}