import { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faExclamationTriangle, faSkullCrossbones } from '@fortawesome/free-solid-svg-icons'
// import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getAccount } from '../../services/Web3'
import './ui.scss'
const { colors } = require('../../assets/config')

// interface BackButtonProps {
//     title?: string
// }

// export const BackButton = ({ title = '' } : BackButtonProps ) => {

//     const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

//     const useStyles = makeStyles((theme: Theme) =>
//         createStyles({
//             root: {
//                 float: 'left',
//                 marginLeft: xs ? 18 : 0
//             },
//             button: {
//                 height: 40,
//                 paddingRight: -20,
//                 borderColor: colors.color2,
//                 borderWidth: 2,
//                 borderRadius: 10,
//                 float: 'left'
//             },
//             icon: {
//                 fontSize: '18px',
//                 color: colors.color4,
//                 marginRight: -10,
//             },
//             title: {
//                 display: title != '' ? 'true' : 'none',
//                 fontSize: '14px',
//                 fontFamily: 'Biryani',
//                 fontStyle: 'normal',
//                 textTransform: 'none',
//                 color: colors.color4,
//                 marginTop: 2,
//                 marginLeft: 10,

//             }
//     }))
//     const history = useHistory()
//     const classes = useStyles()
//     return (
//         <div className={classes.root}>
//         <Button 
//         onClick={()=> history.goBack()}
//         className={classes.button} 
//         size="large"
//         variant="outlined" 
//         color="primary">
//             <ArrowBackIos className={classes.icon} />
//             <div className={classes.title}>{title}</div>
//         </Button>
//         </div>
//     )
// }


// interface InputProps {
//     defaultValue?: string,
//     style?: any,
//     invalid?: boolean,
//     icon?: IconProp,
//     type?: string,
//     label?: string,
//     handleChange?: (any)
// }

// export const Input = ({ 
//     defaultValue = '', 
//     style = {}, 
//     invalid = false, 
//     icon = undefined, 
//     type = "text", 
//     label = '', 
//     handleChange } : InputProps) => {
//     return (
//         <div className="form__group field">
//         {icon &&
//             <FontAwesomeIcon icon={icon} />
//         }
//         <input defaultValue={defaultValue} style={style} onChange={(e)=>handleChange(e.currentTarget.value)} type={type} className="form__field price" required />
//         <label style={invalid ? {color: '#ca0b00'} : {}} htmlFor="name" className="form__label">{label}</label>
//       </div>
//     )
// }

interface OldButtonProps {
    full?: boolean, 
    loading?: boolean, 
    title?: string, 
    disabled?: boolean,
    handleClick?: (any)
}
// TODO: remove
export const OldButton = ({ 
    full = false, 
    loading = false, 
    title = "", 
    disabled = false,
    handleClick } : OldButtonProps) => {
    
    const getClassNames = () => {
        const disabledClass = disabled ? 'buttonDisabled' : ''
        const fullClass = full ? 'full' : 'half'
        return disabledClass+' '+fullClass
    }
    return <button className={getClassNames()} onClick={handleClick}>
            {loading && 
                <i style={{marginRight: '5px'}} className="fa fa-spinner fa-spin"></i>
            }   {title}
        </button>
}

export const CveBadge = ({title, severity} : {title: any, severity: string}) => {

    const show = title != undefined || title != 0

    const getBadgeColor = (severity: string) => {
        switch(severity){
            case 'LOW' :
                return colors.severity.low
            case 'MEDIUM' :
                return colors.severity.medium
            case 'HIGH' :
                return colors.severity.high
            case 'CRITICAL' :
                return colors.severity.critical
            default:
                return 'white'
        }
    }
    return show ? <span 
        style={{marginLeft: '8px', fontSize: '0.8rem', verticalAlign: 'middle', background: getBadgeColor(severity)}} 
        className="badge rounded-pill">
            {title}
        </span> : null
}

// export const OwnerBadge = ({owner} : {owner: string}) => {

//     const [title, setTitle] = useState('')

//     useEffect(() => { 
//         (async () => {
//             const accountAddress = await getAccount()
//             const whoIsTheOwner = accountAddress == owner ? 'Owned by you' : owner
//             setTitle(whoIsTheOwner)
//         })()
//     })

//     return <span 
//         style={{marginLeft: '8px', fontSize: '0.8rem', verticalAlign: 'middle', background: 'green'}} 
//         className="badge rounded-pill">
//             <FontAwesomeIcon icon={faUser}/> {title}
//         </span>
// }

export const Error = ({text} : {text:string}) => <p className="errorText"> {text}</p>
// export const Error = ({text} : {text:string}) => <p className="errorText"><FontAwesomeIcon icon={faExclamationTriangle}/>  {text}</p>

export const Loading = () => {
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            background: colors.color1,
            width: '100%',
            height: '100vh',
            textAlign: 'center'
        },
        progress: {
            marginTop: '50vh',
            left: '50%'
        }
    }))
    const classes = useStyles();

    return <div className={classes.root}>
        <CircularProgress className={classes.progress}/>
    </div>
}

// interface ConnectWalletButtonProps {
//     handleClick?: (any)
// }

// export const ConnectWalletButton = ({ handleClick } : ConnectWalletButtonProps) => {
//     const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             border: `2px solid ${colors.theme}`,
//             color: colors.theme,
//             boxShadow: 'bold',
//             borderRadius: 8,
//             textTransform: 'none',
//             fontFamily: 'Biryani',
//             height: 40,
//             fontSize: 13
//         }
//     }))
//     const classes = useStyles();
//     return  <Button 
//                 onClick={handleClick}
//                 className={classes.root}
//                 size="large">
//               &nbsp;Connect wallet&nbsp;
//           </Button>
// }

// interface PageHeaderSearchBarProps {
//     title: string
//     placeholder?: string
//     handleSearchChange: (text: string) => void
// }

// export const PageHeaderSearchBar = ({ title, placeholder, handleSearchChange } : PageHeaderSearchBarProps) => {

//     const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             color: 'white',
//             height: 40,
//             marginTop: 20,
//             marginLeft: 20,
//             marginRight: 20,
//         },
//         title: {
//             fontSize: 24,
//             fontFamily: 'Kanit',
//             float: 'left'
//         },
//         searchBar: {
//             borderRadius: 8,
//             background: colors.color2,
//             fontFamily: 'Biryani',
//             float: 'right',
//             width: 250,
//             borderWidth: 0,
//             height: 40,
//             color: colors.color4,
//             fontSize: 14,
//             paddingLeft: 20
//         },
//         settingsButton: {
//             width: 40,
//             height: 40,
//             background: colors.color2,
//             float: 'right',
//             borderRadius: 8,
//             marginLeft: 10,
//             color: colors.color4,
//         }
//     }))
//     const classes = useStyles();

//     const validate = (text: string) => {
//         text = text.toUpperCase()
//         if(text.startsWith('CVE-') && text.length > 9) return handleSearchChange(text)
//     }

//     return (
//         <div className={`${classes.root} marginBottomOnMobile`}>
//             <div className={`${classes.title} `}>{title}</div>
//             {/* <Button
//              className={classes.settingsButton}><ArrowBackIos/></Button> */}
//             <input 
//                 onChange={(e)=> validate(e.target.value)}
//                 className={`${classes.searchBar} expandOnMobile`} 
//                 placeholder={placeholder ?? ""}/>
//         </div>
//     )
// }