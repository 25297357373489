
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/config'


export const ScoreBadge = ({ score, severity }: { score: number, severity: string }) => {

    const getBackgroundColor = (severity: string) => {
        switch (severity) {
            case 'LOW':
                return colors.severity.low
            case 'MEDIUM':
                return colors.severity.medium
            case 'HIGH':
                return colors.severity.high
            case 'CRITICAL':
                return colors.severity.critical
            default:
                return colors.color3
        }
    }

    const useStyles = makeStyles({
        root: {
            borderRadius: 5,
            background: getBackgroundColor(severity),
            width: 40,
            height: 35,
            marginTop: 7,
            color: 'black',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 14,
            verticalAlign: 'middle',
            lineHeight: 2.4
        }
    })
    const classes = useStyles();

    return <div className={classes.root}>
        {score ? <div>{score}</div> : '?'}
    </div>
}