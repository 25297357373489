import NFT from './NFT'
import { getWeb3, getAccount, getNetworkId } from './Web3'
import { toEth } from '../hooks/miscHooks'
const json = require('../contracts/Marketplace.json');
const { config } = require('../assets/config');
const { Web3UnavailableError } = require('../assets/errors');
const networkId = getNetworkId()

export default class Marketplace {

    static contract: any

    constructor(){}

    static initContractIfNeeded = async () => {
        if(Marketplace.contract){return}
        const web3 = await getWeb3()
        if(!web3){throw Web3UnavailableError}

        Marketplace.contract = await new web3.eth.Contract(
            json.abi,
            json.networks[networkId].address
        )
    }

    static mint = async (cve: string) => {
        await Marketplace.initContractIfNeeded()
        try {
            console.log("Marketplace.mint")
            const mintFee = await Marketplace.getMintFee()
            // Get the metadata hash before mint
            let apiResponse = await fetch(config.url.api+config.url.metadatahash+"?cve="+cve)
            const metadataHash = await apiResponse.text()
            const address = await getAccount()
            const response = await Marketplace.contract.methods.mintNFT(cve, metadataHash).send({ 
                from: address,
                value: mintFee
            })
            const success = response.transactionHash != undefined
            if(success){ await NFT.sendEvent(response.events.NftMinted) }
            return response

        } catch(error: any){
            if(error.message && error.message.includes("ALREADY_MINTED")){
                return {error: 'ALREADY_MINTED'}
            } else if(error.message && error.message.includes("MetaMask Tx Signature: User denied transaction signature.")){
                return {error: 'USER_REJECTED'}
            }
            console.error(error)
        }
    }

    static approveNFT = async (tokenId: number, onApproved: (success: boolean) => void) => {
        await Marketplace.initContractIfNeeded()

        try {
            const marketplaceAddress = await Marketplace.getContractAddress()
            const owner = await NFT.getApproved(tokenId)
    
            // Ask for approval if marketplace is not approved to handle token
            if(marketplaceAddress != owner){
                const success = await NFT.approve(marketplaceAddress, tokenId)
                return onApproved(success)
            }
            onApproved(false)
        } catch(error: any){
            if(error.message && error.message.includes("MetaMask Tx Signature: User denied transaction signature.")){
                return {error: 'USER_REJECTED'}
            }
            console.error(error)
        }
    }

    static offerNFT = async (tokenId: number, price: string, onTransferred:(success: boolean) => void) => {
        await Marketplace.initContractIfNeeded()

        try {
            const account = await getAccount()
            const response = await Marketplace.contract.methods.offerNFT(tokenId, price).send({ from: account })
            const success = response.transactionHash != undefined
            if(success){ await NFT.sendEvent(response.events.NftOffer) }
            onTransferred(response.transactionHash != undefined)
        } catch(error: any){
            if(error.message && error.message.includes("MetaMask Tx Signature: User denied transaction signature.")){
                return {error: 'USER_REJECTED'}
            }
            console.error(error)
        }
    }

    static withdrawOffer = async (tokenId: number) => {
        await Marketplace.initContractIfNeeded()

        try {
            const account = await getAccount()
            const response = await Marketplace.contract.methods.withdrawOffer(tokenId).send({ from: account })
            const success = response.transactionHash != undefined
            if(success){ await NFT.sendEvent(response.events.NftOfferWithdraw) }
            return response
        } catch(error: any){
            if(error.message && error.message.includes("MetaMask Tx Signature: User denied transaction signature.")){
                return {error: 'USER_REJECTED'}
            }
            console.error(error)
        }
    }

    static buyNFT = async (tokenId: number) => {
        await Marketplace.initContractIfNeeded()

        try {
            const account = await getAccount()
            const marketplaceFee = await Marketplace.contract.methods.getNftPrice(tokenId).call()
            const response = await Marketplace.contract.methods.buyNFT(tokenId).send({ 
                from: account,
                value: marketplaceFee
            })
            const success = response.transactionHash != undefined
            if(success){ await NFT.sendEvent(response.events.NftSold) }
            return response
        } catch(error: any){
            if(error.message && error.message.includes("MetaMask Tx Signature: User denied transaction signature.")){
                return {error: 'USER_REJECTED'}
            }
            console.error(error)
        }
    }

    static getNftPrice = async (tokenId: number) => {
        try {
            await Marketplace.initContractIfNeeded()
            return await Marketplace.contract.methods.getNftPrice(tokenId).call()
        } catch(error){
            if(error === Web3UnavailableError){ throw Web3UnavailableError }
        }
    }

    static getContractAddress = async () => {
        return json.networks[networkId].address
    }

    static getMintFee = async () => {
        await Marketplace.initContractIfNeeded()
        return await Marketplace.contract.methods.mintFee().call()
    }

    static getMarketplaceFee = async () => {
        await Marketplace.initContractIfNeeded()
        return await Marketplace.contract.methods.marketplaceFee().call()
    }

    static getTokensForAccountFromBlockchain = async () => {
        await Marketplace.initContractIfNeeded()
        const address = await getAccount()
        const tokens = await Marketplace.contract.methods.offersByOwner(address).call()
        const result = []
        for(var i = 0; i < tokens.length; i++){result.push({
            id: tokens[i],
            loading: true
        })}
        return result.reverse()
    }

}