
import EventTable from '../nft/components/EventTable'
import { OutlinedIconButton } from '../ui/components'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { getEtherscanUrl } from '../../hooks/miscHooks'
import { UserType } from '../../interfaces'
import OpenInNew from '@material-ui/icons/OpenInNew';
import ShareIcon from '@material-ui/icons/Share';

export const AccountEventTable = ({ user, limit } : { user : UserType | undefined, limit: number }) => {
    
    return user ? (
        <div>
            <EventTable title="Latest activity" account={user!.account} limit={limit}/>
        </div>
    ) : null
}

export const ProfileButtons = ({ isLoggedIn, user } : { isLoggedIn: boolean, user: UserType | undefined }) => {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
            },
            left: {
                float: 'left',
                marginRight: 10
            },
            right: {
                float: 'left',
            }
    }))

    const classes = useStyles();

    return  (
        <div className={classes.root}>
           {isLoggedIn && 
            <div className={classes.left}>
                <OutlinedIconButton title={'Share profile'}  icon={<ShareIcon/>} handleClick={()=> window.open(window.location.href, '_blank')}/>
            </div>}
            <div className={classes.right}>
                <OutlinedIconButton title={isLoggedIn ? 'Etherscan' : 'View on Etherscan'}  icon={<OpenInNew/>} handleClick={()=> window.open(getEtherscanUrl()+'address/'+user!.account, '_blank')}/>
            </div>
        </div>
    )
}
