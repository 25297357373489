import { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { BackButton } from '../ui/components'
import { OutlinedIconButton } from '../ui/components'
import { colors, config } from '../../assets/config'
import { switchNetwork } from '../../services/Web3'

export default function Tutorial() {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
    const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
    const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
    const xl = useMediaQuery('min-width: 1920px');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1,
                background: colors.color1,
                padding: xs ? 5 : 20
            },
            container: {
                color: 'white',
                marginTop: 50,
                marginBottom: 50,
                margin: 20
            },
            backButton: {
                marginTop: 30,
                marginLeft:  xs ? 0 : 30,
            },
            arbitrumImage: {
                height: 51,
                float: 'left',
                marginRight: 20
            },
            title: {
                fontFamily: 'Kanit',
                fontSize: 36,
            },
            intro: {
                fontFamily: 'Biryani',
                fontSize: 16,
                width: xs || sm ? '100%' : '60%'
            },
            stepTitle: {
                fontFamily: 'Kanit',
                fontSize: 24,
                marginTop: 40
            },
            stepImage: {
                height: 150,
                float: 'left',
                marginRight: 30,
                marginTop: 20
            },
            stepOneText: {
                fontFamily: 'Biryani',
                fontSize: 16,
                marginTop: 20
            },
            stepOneNetwork: {
                fontFamily: 'Biryani',
                fontWeight: 'bold',
                fontSize: 16,
                marginTop: 0,
                float: 'left'
            },
            stepTwoGuide: {
                fontFamily: 'Biryani',
                fontSize: 16,
                marginTop: 30,
            },
            done: {
                fontFamily: 'Kanit',
                fontSize: 24,
                fontWeight: 'bold',
                marginTop: 40,
                float: 'left'
            },
            mintButton: {
                marginTop: 19,
                marginLeft: 20,
                float: 'left'
            },
            link: {
                color: 'white',
                cursor: 'pointer',
                fontWeight: 'bold',
            }
    }))

    const classes = useStyles()
    const history = useHistory()

    return (
        <>
            <div className={classes.backButton}>
                <BackButton title="Back"/>
            </div>
            <Container className={classes.root}>
                <div className={classes.container}>
                    <img 
                        className={classes.arbitrumImage}
                        src="/images/arbitrum.png"/>
                    <div className={classes.title}>Arbitrum tutorial</div>
                    <br/>
                    <div className={classes.intro}>We are live on Arbitrum, an Ethereum layer 2 blockchain where you can mint and trade with smaller fees than Ethereum layer 1 by bridging your eth.<br/><br/>
                    In this tutorial, we will explain how to use Arbitrum layer 2, connect with Metamask and bridge ETH to Arbitrum. It’s easier than you think!
                    </div>
                    <div className={classes.stepTitle}>
                        Step 1 - Adding Arbitrum network
                    </div>
                    <img 
                        className={classes.stepImage}
                        src="/images/tutorial-step1.png"/>
                    <div className={classes.stepOneText}>
                        To connect to Arbitrum, you’ll have to add the Arbitrum One network to your browser wallet extension.
                    </div>
                    <div className={classes.stepOneNetwork}>
                        <OutlinedIconButton title="Add network" icon={<ArrowForwardIos fontSize="small"/>}  color={colors.theme}  handleClick={()=> switchNetwork()}/>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className={classes.stepTitle}>
                        Step 2 - Bridging ETH
                    </div>
                    <img 
                        className={classes.stepImage}
                        src="/images/tutorial-step2.png"/>
                    <div className={classes.stepOneText}>
                        To get started using Arbitrum, you’ll need to deposit ether from the Ethereum Mainnet across to Arbitrum, using a bridge. A bridge that you can use for this process is <a className={classes.link} onClick={()=> {window.open(config.link.arbitrumBridge, '_blank')}}> Offchain Labs’ Arbitrum One Bridge.</a>
                        <br/>
                        <br/>
                        If you don’t already have any Etheruem to get started with, you can purchase some on popular cryptocurrency exchanges such as Binance, FTX or Huobi with either cryptocurrency or FIAT.
                    </div>
                    <br/>
                    <div className={classes.stepTwoGuide}>
                        <strong>Connect your web3 wallet (which will need to be connected to the Ethereum mainnet).
                        Select your token and enter in the amount you’d like to bridge.
                        Choose to “Deposit".
                        Read the warning and terms and then choose “Deposit” when you are happy
                        Confirm within your connected wallet and pay the associated gas fee.
                        It may then take up to 10 minutes for your balance to appear on Arbitrum.</strong>
                        <br/>
                        <br/>
                        When bridging your tokens, it’s really important to note that there is approximately a 7 day withdrawal period. This is if you want to reverse the process and send your tokens back from Arbitrum to the Ethereum Mainnet.
                    </div>
                    
                    <OutlinedIconButton title="Mint" icon={<ArrowForwardIos fontSize="small"/>}  color={colors.theme}  handleClick={()=> history.push('/')}/>
                </div>
            </Container>
        </>)
}