
import { useState, useContext } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import MintDialog from '../dialogs/MintDialog'
import SaleDialog from '../dialogs/SaleDialog'
import ErrorDialog from '../dialogs/ErrorDialog'
import InfoDialog from '../dialogs/InfoDialog'
import AccountContext from '../../../contexts/AccountContext'
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Marketplace from '../../../services/Marketplace'
import CircularProgress from '@material-ui/core/CircularProgress';
import { NftType } from '../../../interfaces'
import { error, event } from '../../../assets/firebase'
import { config, colors } from '../../../assets/config'

export default function NftCard({ token, price } : { token: NftType, price: string | undefined}){

    const useStyles = makeStyles({
        root: {
            backgroundColor: 'transparent',
            width: 328,
            height: 472,
            perspective: 1000,
        },
        card: {
            borderRadius: 15,
            background: colors.color2,
            position: 'absolute',
            width: '100%',
            height: '100%',
            fontWeight: 'bold',
            fontSize: 40,
            backfaceVisibility: 'hidden',
        },
        placeholderText: {
            margin: 'auto',
            marginTop: 50,
            marginBottom: 50,
            paddingTop: 100,
            width: 220,
            height: 220,
            lineHeight: 1,
            color: 'white',
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Kanit'
        },
        media: {
            margin: 'auto',
            marginTop: 50,
            marginBottom: 50,
            width: 220,
            height: 220,
        },
    })

    const classes = useStyles();  
    
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                {token.tokenId ? (
                    <CardMedia
                    className={`${classes.media}`}
                    image={token.imageUrl}
                    title="Contemplative Reptile"/>
                    ) : (
                        <div className={classes.placeholderText}>NOT YET MINTED</div>
                        )}
                <NftCardActions 
                    token={token}
                    isForSale={price != undefined && price != '0'}/>
            </Card>
        </div>
    )
}

const NftCardActions = ({ token, isForSale }: { token: NftType, isForSale: boolean }) => {

    const useStyles = makeStyles({
        root: {
            textAlign: 'center',
            width: '100%',
            height: '100%',
            marginTop: 57,
        }
    })
    
    const classes = useStyles();    

    return token ? (
        <CardActions>
            <div className={classes.root}>
                <LeftButton {...token}/>
                <RightButton token={token} isForSale={isForSale}/>
            </div>
        </CardActions>
    ) : null
}


const RightButton = ({ token, isForSale } : { token: NftType, isForSale: boolean }) => {

    const { user } = useContext(AccountContext);
    const accountIsReady = user != undefined
    const isOwner = accountIsReady && token.owner == user!.account
    if(isOwner && !isForSale){
        return <PutUpForSaleButton {...token}/>
    } else if(isOwner && isForSale) {
        return <RemoveOfferButton {...token}/>
    } else if(!isOwner && isForSale){
        return <PurchaseButton {...token}/>
    } else if(!token.tokenId && !isForSale) {
        return <MintButton {...token}/>
    }
    return null
}

const LeftButton = (token: NftType) => {
    const { user } = useContext(AccountContext);
    const isOwner = user != null && token.owner == user.account
    return !isOwner ? <WatchlistButton/> : null
}

const MintButton = (token: NftType) => {

    const [openMintDialog, setOpenMintDialog] = useState(false)
    const [metamaskIsLoading, setMetamaskIsLoading] = useState(false)
    const { user } = useContext(AccountContext);

    const useStyles = makeStyles({
        rightButton: {
            border: `2px solid ${colors.theme}`,
            color: colors.theme,
            boxShadow: 'bold',
            borderRadius: 8,
            textTransform: 'none',
            fontFamily: 'Biryani',
            paddingLeft: 20,
            paddingRight: 20,
            marginLeft: 15,
            opacity: !metamaskIsLoading ? 1 : 0.3,
            pointerEvents: !metamaskIsLoading ? 'auto' : 'none',
        },
        circularProgress: {
            color: colors.theme,
            marginTop: -3,
            marginRight: 5
        }
    })

    const classes = useStyles()
    const history = useHistory()
    const [errorCode, setErrorCode] = useState('')
    
    const mint = async () => {
    
        if(metamaskIsLoading){return}
        if(!user){return}
        
        setMetamaskIsLoading(true)
        setOpenMintDialog(false)

        // Create NFT placeholder
        const body = { cve: token.id, owner: user.account }
        let apiResponse = await axios.post(config.url.api+config.url.createNftPlaceholder, body)
        console.log("Create NFT placeholder: ",apiResponse)
        
        // Error on crate NFT placeholder
        if(apiResponse.data.id != token.id){
            console.error(apiResponse)
            setMetamaskIsLoading(false)
            return setErrorCode('minting_error')
        }

        // Mint NFT
        const response = await Marketplace.mint(token.id)
        console.log("Mint NFT: ",response)

        // Error on Mint NFT
        if(response.error) {
            setMetamaskIsLoading(false)
            switch(response.error){
                case 'ALREADY_MINTED' :
                    error('already_minted',{ token: token })
                    setErrorCode('already_minted_error')
                    break
                case 'USER_REJECTED' :
                    break
                default: 
                    error('mint',{ token: token })
                    setErrorCode('minting_error')
            }
        } else if(response.events.NftMinted?.returnValues?.cve != token!.id){
            console.error(response)
            setMetamaskIsLoading(false)
            return setErrorCode('minting_error')
        }

        if(response && response.transactionHash){
            
            // Go to profile
            setMetamaskIsLoading(false)
            event('minted', { token: token})
            history.push("/profile/"+user.account)
        }
    }
    
    const handleClick = () => {
        if(user)
            setOpenMintDialog(!openMintDialog)
        else
            setErrorCode('wallet_error')
    }

    return (
        <>
        <InfoDialog message="You need to connect your wallet with Metamask first!" open={errorCode == 'wallet_error'} onClose={()=> setErrorCode('')}/>
        <ErrorDialog message="This NFT is already minted!" open={errorCode == 'already_minted_error'} onClose={()=> setErrorCode('')}/>
        <ErrorDialog message="Could not mint NFT!" open={errorCode == 'minting_error'} onClose={()=> setErrorCode('')}/>
         <MintDialog 
            onConfirm={()=>mint()}
            onClose={()=> setOpenMintDialog(false)} 
            open={openMintDialog} 
            token={token}/>
        <Button 
            className={classes.rightButton} 
            size="large" 
            onClick={()=> handleClick()}>
            {metamaskIsLoading && <CircularProgress size={18} className={classes.circularProgress}/>}
            Mint NFT
        </Button>
        </>
    )
}

const PutUpForSaleButton = (token: NftType) => {

    const [openSaleDialog, setOpenSaleDialog] = useState(false)
    const [metamaskIsLoading, setMetamaskIsLoading] = useState(false)
    const [errorCode, setErrorCode] = useState('')
    const [title, setTitle] = useState('Put up for sale')

    const useStyles = makeStyles({
        rightButton: {
            border: `2px solid ${colors.theme}`,
            color: colors.theme,
            boxShadow: 'bold',
            borderRadius: 8,
            textTransform: 'none',
            fontFamily: 'Biryani',
            paddingLeft: 20,
            paddingRight: 20,
            marginLeft: 15,
            opacity: !metamaskIsLoading ? 1 : 0.3,
            pointerEvents: !metamaskIsLoading ? 'auto' : 'none',
        },
        circularProgress: {
            color: colors.theme,
            marginTop: -3,
            marginRight: 5
        }
    })

    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        setOpenSaleDialog(true)
    }

    return (
        <>
        <ErrorDialog message="Could not grant marketplace approval to handle your NFT" open={errorCode == 'approved_error'} onClose={()=> setErrorCode('')}/>
        <ErrorDialog message="Could transfer your NFT to the marketplace" open={errorCode == 'transferred_error'} onClose={()=> setErrorCode('')}/>
        <SaleDialog  
            onConfirm={async ()=> {
                setOpenSaleDialog(false)
                setMetamaskIsLoading(false)
                event('offered', { token: token })
                history.push(`/profile/${token.owner}`)
            }}
            onClose={()=> setOpenSaleDialog(false)} 
            open={openSaleDialog} 
            token={token}/>
        <Button 
            className={classes.rightButton} 
            size="large"
            onClick={()=> handleClick()}>
            {metamaskIsLoading && <CircularProgress size={18} className={classes.circularProgress}/>}
            {title}
        </Button>
        </>
    )
}

const PurchaseButton = (token: NftType) => {

    const [metamaskIsLoading, setMetamaskIsLoading] = useState(false)
    const [errorCode, setErrorCode] = useState('')
    
    const useStyles = makeStyles({
        rightButton: {
            border: `2px solid ${colors.theme}`,
            color: colors.theme,
            boxShadow: 'bold',
            borderRadius: 8,
            textTransform: 'none',
            fontFamily: 'Biryani',
            paddingLeft: 20,
            paddingRight: 20,
            marginLeft: 15,
            opacity: !metamaskIsLoading ? 1 : 0.3,
            pointerEvents: !metamaskIsLoading ? 'auto' : 'none',
        },
        circularProgress: {
            color: colors.theme,
            marginTop: -3,
            marginRight: 5
        }
    })

    const classes = useStyles()
    const history = useHistory()
    const { user } = useContext(AccountContext)
    const accountIsReady = user != undefined

    const handleClick = async () => {

        if(!user || user.account == ''){return setErrorCode('wallet_error')}

        setMetamaskIsLoading(true)
        const response = await Marketplace.buyNFT(token.tokenId)
        if(response && response.transactionHash){
            setMetamaskIsLoading(false)
            event('purchased', {token: token})
            history.push("/tokens")
        } else if(response.error) {
            setMetamaskIsLoading(false)
            switch(response.error){
                case 'USER_REJECTED' :
                    break
                default: 
                    error('purchase', { token: token })
                    setErrorCode('purchase_error')
            }
        }
    }

    return accountIsReady ? (
        <>
        <ErrorDialog message="Could not purchase NFT!" open={errorCode == 'purchase_error'} onClose={()=> setErrorCode('')}/>
            <Button 
                className={classes.rightButton} 
                size="large"
                onClick={()=> handleClick()}>
                {metamaskIsLoading && <CircularProgress size={18} className={classes.circularProgress}/>}
                Purchase
            </Button>
        </>) : null
}

const RemoveOfferButton = (token: NftType) => {

    const [metamaskIsLoading, setMetamaskIsLoading] = useState(false)
    const [errorCode, setErrorCode] = useState('')

    const useStyles = makeStyles({
        rightButton: {
            border: `2px solid ${colors.theme}`,
            color: colors.theme,
            boxShadow: 'bold',
            borderRadius: 8,
            textTransform: 'none',
            fontFamily: 'Biryani',
            paddingLeft: 20,
            paddingRight: 20,
            marginLeft: 15,
            opacity: !metamaskIsLoading ? 1 : 0.3,
            pointerEvents: !metamaskIsLoading ? 'auto' : 'none',
        },
        circularProgress: {
            color: colors.theme,
            marginTop: -3,
            marginRight: 5
        }
    })

    const classes = useStyles()

    const handleClick = async () => {
        setMetamaskIsLoading(true)
        const response = await Marketplace.withdrawOffer(token.tokenId)
        if(response && response.transactionHash){
            setMetamaskIsLoading(false)
            event('withdrawn', {token: token})
            window.location.reload()
        } else if(response.error) { // Error handling
            setMetamaskIsLoading(false)
            switch(response.error){
                case 'USER_REJECTED' :
                    break
                default: 
                    error('withdraw', { token: token })
                    setErrorCode('withdraw_error')
            }
        }
    }

    return (
        <>
        <ErrorDialog message="Could not withdraw your NFT from the marketplace" open={errorCode == 'withdraw_error'} onClose={()=> setErrorCode('')}/>
        <Button 
            className={classes.rightButton} 
            size="large"
            onClick={()=> handleClick()}>
            {metamaskIsLoading && <CircularProgress size={18} className={classes.circularProgress}/>}
            Remove from sale
        </Button>
        </>
    )
}

const WatchlistButton = () => {

    const useStyles = makeStyles({
        leftButton: {
            border: `2px solid ${colors.color4}`,
            color: colors.color4,
            boxShadow: 'none',
            borderRadius: 8,
            textTransform: 'none',
            fontFamily: 'Biryani',
            paddingLeft: 15,
            paddingRight: 15,
        },
    })

    const classes = useStyles()
    return (
        <Button 
            className={classes.leftButton} 
            size="large"
            onClick={()=> alert('Feature coming soon')}>
            <FavoriteBorderIcon/>&nbsp;
            Watchlist
        </Button>
    )
}
