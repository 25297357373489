import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { NftType } from '../../../interfaces'
import { colors } from '../../../assets/config'
import { getCardStyles } from '../../../hooks/miscHooks'
import NFT from '../../../services/NFT';

const convert = require('ether-converter')

const ScoreBadge = ({ score, severity }: { score: number, severity: string }) => {

    const getBackgroundColor = (severity: string) => {
        switch (severity) {
            case 'LOW':
                return colors.severity.low
            case 'MEDIUM':
                return colors.severity.medium
            case 'HIGH':
                return colors.severity.high
            case 'CRITICAL':
                return colors.severity.critical
            default:
                return colors.color3
        }
    }

    const useStyles = makeStyles({
        root: {
            borderRadius: 2.8,
            background: getBackgroundColor(severity),
            color: '#1F1F34',
            width: 28,
            height: 25,
            marginTop: 5,
            marginLeft: -2,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Kanit',
            fontSize: 12,
            verticalAlign: 'middle',
            lineHeight: 1.9
        }
    })
    const classes = useStyles();

    return  <div className={classes.root}>
                {score ? <div>{score}</div> : '?'}
            </div>
}

export const NftCardSmall = ({ token } : { token: NftType } ) => {

    const [_token, setToken] = useState(token)

    const useStyles = makeStyles({
        container: {
            cursor: _token.status == 'minted' ? 'pointer' : 'false',
            backgroundColor: 'transparent',
            width: 156,
            height: 240,
            perspective: 1000,
            float: 'left',
            pointerEvents: _token.status == 'minted' ? 'auto' : 'none',
            transition: _token.status == 'minted' ? 'transform .2s' : 'false',
            '&:hover': _token.status == 'minted' ? {
                transform: 'scale(0.98)'
            } : 'false',
        },
        card: {
            position: 'relative',
            width: '100%',
            height: '100%',
            transformStyle: 'preserve-3d',
            transformOrigin: 'center right',
            transition: 'transform 1s'
        },
        media: {
            margin: 'auto',
            marginTop: 25,
            marginBottom: 15,
            width: 110,
            height: 110
        },
        id: {
            color: colors.color8,
            fontWeight: 'normal',
            fontSize: 12,
            fontFamily: 'Kanit',
            textAlign: 'left',
        },
        tokenId: {
            color: colors.color3,
            marginTop: -19,
            fontSize: 13,
            textAlign: 'left',
            fontWeight: 'normal',
            fontFamily: 'Biryani'
        },
        price: {
            color: colors.color7,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'right',
            marginTop: 20,
            marginRight: 6
        },
        cardActions: {
            textAlign: 'center',
            width: '100%',
            height: '100%',
            marginTop: -57,
        },
        inspectButton: {
            background: colors.color1,
            color: colors.color3,
            height: 26,
            width: 70,
            fontSize: 10,
            boxShadow: 'none',
            borderRadius: 4,
            textTransform: 'none',
            fontFamily: 'Biryani',
            paddingLeft: 'auto',
            paddingRight: 'auto',
            marginLeft: 2,
            lineHeight: 0.2,
            textAlign: 'left'
        },
        placeholder: {
            borderRadius: 7,
            background: colors.color2,
            opacity: 0.7,
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
        },
        circularProgressContainer: {
            margin: 'auto',
            marginTop: 25,
            marginBottom: 15,
            minWidth: 110,
            minHeight: 110
        },
        circularProgress: {
            color: colors.color3,
            marginTop: 50
        }
    })
    const classes = useStyles()
    const history = useHistory()

    useEffect(()=> {
       (async () => {
        if(_token.status == 'started'){
            const response = await NFT.sync(_token.id)
            if(response.data.status == 'minted'){ setToken(response.data) }
        }
       })()
    },[])

    const NftCardActions = () => {

        return (
            <CardActions>
                <div className={classes.cardActions}>
                <Grid item lg>
                    {_token.price && <div className={classes.price}>Ξ {convert(_token.price, 'wei').ether}</div>}
                </Grid>
                </div>
            </CardActions>
        )
    }

    const Front = () => {
        const useStyles = makeStyles({
            root: {
                borderRadius: 7,
                background: colors.color2,
                width: '100%',
                height: '100%',
                fontWeight: 'bold',
                fontSize: 40,
                backfaceVisibility: 'hidden',
            },
            titleContainer: {
                float: 'left',
                marginRight: 5
            }
        })
        const cardFrontClasses = useStyles();
        const styleClasses = getCardStyles(_token)
        return (
            <Card className={cardFrontClasses.root+styleClasses}>
                {_token.status == 'started' ?
                    <div className={classes.circularProgressContainer}>
                        <CircularProgress size={22} className={classes.circularProgress}/>
                    </div> : 
                    <CardMedia
                    className={classes.media}
                    image={_token.imageUrl}
                    title={_token.id}
                />}
                <CardContent>
                    <div>
                        <div className={cardFrontClasses.titleContainer}>
                            <ScoreBadge score={_token.score} severity={_token.severity} />
                        </div>
                        <div>
                            <p id="token-id" className={classes.id}>
                                {_token.id}
                            </p>
                            <p className={classes.tokenId}>{_token.tokenId ? '#'+_token.tokenId : '...'}</p>
                        </div>
                    </div>
                </CardContent>
                <NftCardActions/>
            </Card>
        )
    }

    return (
        <div className={classes.container} onClick={()=> history.push(`/token/${_token.tokenId}`)}>
            <div className={classes.card}>
                <Front/>
            </div>
        </div>
    )
}

export const NftCardSmallPlaceholder = ({ index } : { index: number }) => {
   
    const useStyles = makeStyles({
        root: {
            width: 156,
            height: 240,
            float: 'left',
            borderRadius: 8,
            marginBottom: 10,
            background: '#65658a59'
        },
        card: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        placeholder: {
            borderRadius: 7,
        },
        media: {
            margin: 'auto',
            marginTop: 20,
            marginBottom: 30,
            width: 110,
            height: 110
        },
        score: {
            width: 30,
            height: 30,
            float: 'left',
            marginLeft: 23,
            marginRight: 10,
        },
        id: {
            width: 73,
            height: 18,
            margin: 'auto',
            marginRight: 25,
        },
        tokenId: {
            width: 30,
            height: 6,
            marginTop: 5,
            marginLeft: 58,
        },
        price: {
            width: 110,
            height: 10,
            margin: 'auto',
            marginTop: 10,
            borderRadius: 7,
        }
    })
    const classes = useStyles();
    const opacities = [0.7, 0.6, 0.4, 0.2, 0.1, 0.05]
    return (
        <div className={`${classes.root}`} style={{opacity: opacities[index]}}>
            <div className={classes.card}>
                <div className ={classes.placeholder}>
                    <div className={`${classes.media} animate`}></div>
                    <div className={`${classes.score} animate`}></div>
                    <div className={`${classes.id} animate`}></div>
                    <div className={`${classes.tokenId} animate`}></div>
                    <div className={`${classes.price} animate`}></div>
                </div>
            </div>
        </div>
    )
}
