import { Component } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Grid from '@material-ui/core/Grid';
import NFT from '../../services/NFT'
import { getEtherscanUrl } from '../../hooks/miscHooks'
import Marketplace from '../../services/Marketplace'
import { Loading, Error } from '../ui/ui'
import NftCard from '../ui/card/NftCard'
import { Tags } from '../ui/components'
import { NFTheader } from './components'
import EventTable from './components/EventTable'
import { OutlinedIconButton, BackButton } from '../ui/components'
import { NftType } from '../../interfaces'
import { event, error } from '../../assets/firebase'
import { getTagsForToken } from '../../hooks/miscHooks'

const { 
    NftNotFoundError, 
    Web3UnavailableError } = require('../../assets/errors')

type Props = {
    match: any
    location: any
}

type State = {
    token?: NftType
    error: any
    price?: string
    web3Error: boolean
}

export default class NFTtoken extends Component<Props, State>  {

    constructor(props:Props){
        super(props)
        this.state = {
            token: undefined,
            error: undefined,
            price: undefined,
            web3Error: false
        }
    }

    componentDidMount(){
        let token = this.props.location.state?.token
        const id = this.props.match.params.id
        if(token){ // coming from search
            event('searched_cve', { token: token})
            return this.setState({token: token})
        } else if( /CVE-\d{4}-\d{1,9}/.test(id.toUpperCase())) {
            return this.fetchTokenFromApiByCve(id)
        } else if(Number(id)){
            return this.fetchTokenFromApiByTokenId(id)
        } 
        error('token_id')
        this.setState({error: 'Invalid token id'})
    }

    syncOwner = async () => {
        if(!this.state.token){ return }
        const isOwnedByMe = await NFT.isOwnedByMe(this.state.token.tokenId)
        if(!isOwnedByMe){ NFT.sync(this.state.token.id) }
    }

    fetchTokenFromApiByCve = async (cve: string) => {
        const token = await NFT.getTokenFromApiByCve(cve)
        this.handleTokenResponse(token)
    }

    fetchTokenFromApiByTokenId = async (tokenId: number) => {
        const token = await NFT.getTokenFromApiByTokenId(tokenId)
        this.handleTokenResponse(token)
    }

    handleTokenResponse = (token: any) => {
        if(token.error){
            switch(token.error){
                case NftNotFoundError :
                    console.error(token.error)
                    return this.setState({ error: 'NFT token can not be found!'})
                default :
                    error('token_response')
            }
        }
        token = token as NftType
        token.tags = getTagsForToken(token)
        this.checkOfferOnBlockchain(token.tokenId)
        this.setState({token: token, error: undefined})
        this.syncOwner()
        event('clicked_token', { token: token})
    }

    checkOfferOnBlockchain = async (tokenId: number) => {
        try {
            const wei = await Marketplace.getNftPrice(tokenId)
            this.setState({ price: wei })
        } catch(err){
            if(err == Web3UnavailableError){
                error('offer_web3_unavailable')
                const price = this.state.token!.price ?? '0'
                return this.setState({price: price, web3Error: true})
            }
        }
    }

    fetchTokenFromBlockchain = async (tokenId: number) => {

        // Get token from blockchain
        const nft = await NFT.getTokenByIdFromBlockchain(tokenId)
        if(!nft){
            error('token_not_found_on_blockchain')
            return this.setState({error: 'Token not found on blockchain'})
        }
    }

    render(){

        const NftView = () => {

            const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
            const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');
            const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)');
            const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)');
            const xl = useMediaQuery('min-width: 1920px');
        
            const useStyles = makeStyles((theme: Theme) =>
                createStyles({
                    root: {
                        paddingTop: 90,
                        flexGrow: 1,
                    },
                    leftGrid: {
                        flexBasis: '0%',
                        marginBottom: 50
                    },
                    rightGrid: {
                        paddingLeft: md || lg || xl ? 90 : 10,
                        paddingRight: md || lg || xl ? 90 : 10,
                        marginBottom: 50
                    },
                    backButton: {
                        marginTop: 30,
                        marginLeft:  xs ? 0 : 30,
                    }
                }))
            const classes = useStyles();
            return this.state.token ? <>
            <div className={classes.backButton}>
                <BackButton title="Back"/>
            </div>
            <Container className={classes.root}>
                <Grid container justifyContent="center">
                    <Grid className={classes.leftGrid} item xs={12} sm={12} md={5} lg={4} xl={4}>
                        <NftCard 
                            price={this.state.price} 
                            token={this.state.token}/>
                    </Grid>
                    <Grid className={classes.rightGrid} item xs={12} sm={10} md={10} lg={8} xl={8}>
                        <NFTheader 
                            price={this.state.price} 
                            token={this.state.token}/>
                        <Tags tags={this.state.token.tags}/>
                        {this.state.token.address != undefined ? <OutlinedIconButton title={'View on Etherscan'} icon={<OpenInNew/>} handleClick={()=> window.open(getEtherscanUrl()+'address/'+this.state.token!.address, '_blank')}/> : null}
                        <EventTable title="Activity" tokenId={this.state.token.tokenId} limit={5}/>
                    </Grid>
                </Grid>
            </Container>
            </> : null
        }

        // Token not found on blockchain
        if(this.state.error){return <Error text={this.state.error}/>}
        return !this.state.token ? <Loading/> :  <NftView/>
    }
}
