import { useState, ReactNode, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import NftCardSmallPreview from '../card/NftCardSmallPreview'
import { colors } from '../../../assets/config'
import { NftType } from '../../../interfaces'
import Marketplace from '../../../services/Marketplace'
import NFT from '../../../services/NFT'
import { toEth } from '../../../hooks/miscHooks'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      background: colors.color2,
      fontFamily: 'Kanit',
      fontSize: 24,
      color: 'white',
      textAlign: 'center'
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: ReactNode;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      <div>You are about to mint</div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    overflow: 'none',
    background: colors.color2,
    fontFamily: 'Kanit',
    fontSize: 24,
    color: 'white'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: colors.color2,
    textAlign: 'center'
  },
}))(MuiDialogActions);

interface MintDialogProps {
  token: NftType,
  open: boolean,
  onConfirm: () => void,
  onClose: () => void
}

export default function MintDialog({ token, open, onConfirm, onClose }: MintDialogProps) {

  const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
  const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: 'white',
        minWidth: xs ? '90%' : 400,
        marginLeft: xs ? '5%' : 0,
        marginRight: xs ? '5%' : 0,
        borderRadius: 16
      },
      score: {
        float: 'left',
        marginRight: 14,
      },
      id: {
        fontFamily: 'Kanit',
        fontSize: xs || sm ? 28 : 36,
        lineHeight: xs || sm ? 1.7 : 1.3,
      }
    }))

  const classes = useStyles();
  const [fee, setFee] = useState<string | undefined>()
  const [nftPreviewImageBase64, setNftPreviewImageBase64] = useState<string | undefined>()
  const [nextTokenId, setNextTokenId] = useState<number | undefined>()

  const fetchFeeFromBlockchain = async () => {
    const _fee = await Marketplace.getMintFee()
    setFee(toEth(_fee))
  }

  const fetchPreviewFromApi = async () => {
    const preview = await NFT.getPreview(token.id)
    if(preview.imageBase64){
      setNftPreviewImageBase64(preview.imageBase64)
    }
  }

  const fetchNextTokenIdFromBlockchain = async () => {
    const nextTokenId = await NFT.getNextTokenIdFromBlockchain()
    setNextTokenId(nextTokenId)
  }

  useEffect(() => {
    fetchPreviewFromApi()
    fetchFeeFromBlockchain()
    fetchNextTokenIdFromBlockchain()
  },[])

  const Card = () => {

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginTop: 5,
        width: '100%',
        height: 250,
        textAlign: 'center',
        pointerEvents: 'none',
        marginBottom: 10
      },
      cardContainer: {
        filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))',
        margin: 'auto',
        borderRadius: 5,
        width: 156,
        height: 240,
      }
    }))

    const classes = useStyles();

    return (
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          <NftCardSmallPreview 
            id={token.id}
            tokenId={nextTokenId}
            imageBase64={nftPreviewImageBase64}
            severity={token.severity}
            score={token.score}/>
        </div>
      </div>
    )
  }

  const Fee = () => {

    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          fontFamily: 'Biryani',
          fontSize: 13,
          color: colors.color3,
          marginTop: 20
        },
        circularProgress: {
          color: colors.color3,
          marginLeft: 5,
          marginBottom: -3
        }
      }))
    const classes = useStyles();

    return (
      <>
        <div className={classes.root} style={{ float: 'left' }}>Marketplace fee</div>
        <div className={classes.root} style={{ float: 'right' }}><strong>{fee ? `${fee} Eth` : <CircularProgress size={14} className={classes.circularProgress} />}</strong></div>
      </>
    )
  }

  const CancelButton = () => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          border: `2px solid ${colors.color4}`,
          color: colors.color4,
          boxShadow: 'none',
          borderRadius: 8,
          textTransform: 'none',
          fontFamily: 'Biryani',
          paddingLeft: 15,
          paddingRight: 15,
        }
      }))
    const classes = useStyles();
    return (
      <Button
        className={classes.root}
        onClick={onClose}
        size="medium">
        Cancel
      </Button>
    )
  }

  const MintButton = ({ disabled }: { disabled: boolean }) => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          border: `2px solid ${colors.theme}`,
          color: colors.theme,
          boxShadow: 'bold',
          borderRadius: 8,
          textTransform: 'none',
          fontFamily: 'Biryani',
          paddingLeft: 20,
          paddingRight: 20,
          marginLeft: 15,
          opacity: !disabled ? 1 : 0.3,
          pointerEvents: !disabled ? 'auto' : 'none',
        }
      }))
    const classes = useStyles();

    const handleOnClick = () => {
      onConfirm()

    }

    return (
      <Button
        className={classes.root}
        size="medium"
        onClick={handleOnClick}>
        Confirm
      </Button>
    )
  }

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby="mint-dialog-title"
      open={open}>
      <DialogTitle id="mint-dialog-title">
      </DialogTitle>
      <DialogContent>
        <Card/>
        <Fee />
      </DialogContent>
      <DialogActions>
        <CancelButton />
        <MintButton disabled={fee == null} />
      </DialogActions>
    </Dialog>
  );
}

