
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { colors, config } from '../../../assets/config'
import { event } from '../../../assets/firebase'

export default function Footer(){

    const useStyles = makeStyles({
        root: {
           minHeight: 250,
           width: '100%',
           background: colors.color2,
           color: colors.color5,
           bottom: 0,
           marginTop: 80,
           paddingTop: 30,
           fontFamily: 'Biryani',
           textAlign: 'left'
        },
        gridOne:{
            paddingRight: 40,
            marginBottom: 50
        },
        gridTwo: {
            paddingRight: 20,
            marginBottom: 50
        },
        gridThree: {
            marginBottom: 50
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold'
        },
        text: {
            fontSize: 13,
            marginTop: 20
        },
        item: {
            fontSize: 13,
            marginTop: 20,
            cursor: 'pointer'
        }
    })
    const classes = useStyles()
    const history = useHistory()

    return(
        <div className={classes.root}>
            <Container>
                <Grid container justifyContent="center">
                    <Grid className={classes.gridOne} item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className={classes.title}>Crypto CVE’s</div>
                    <div className={classes.text}>
                        A CVE is a software vulnerability in the cybersecurity world. Here you can mint unique and famous CVE’s as collectibles, hold or trade with others.
                    </div>
                    </Grid>
                    <Grid className={classes.gridTwo} item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <div className={classes.title}>Marketplace</div>
                        {/* <div className={classes.item} onClick={()=> history.push('/topsellers')}>Top sellers</div> */}
                        <div className={classes.item} onClick={()=> history.push('/new')}>Newly minted</div>
                        <div className={classes.item} onClick={()=> history.push('/unique')}>Unique mints</div>
                    </Grid>
                    <Grid className={classes.gridThree} item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <div className={classes.title}>Project</div>
                        {/* <div className={classes.item} onClick={()=> { event('clicked_whitepaper'); alert('coming soon')}}>Whitepaper</div> */}
                        <div className={classes.item} onClick={()=> { event('clicked_github'); window.open(config.link.github, '_blank')}}>Github</div>
                        <div className={classes.item} onClick={()=> { event('clicked_team'); window.open(config.link.playground, '_blank')}}>Playground</div>
                    </Grid>
                    <Grid className={classes.gridThree} item xs={12} sm={6} md={2} lg={2} xl={2}>
                        <div className={classes.title}>Communiy</div>
                        <div className={classes.item} onClick={()=> { event('clicked_discord'); window.open(config.link.discord, '_blank')}}>Join our discord</div>
                        <div className={classes.item} onClick={()=> { event('clicked_twitter'); window.open(config.link.twitter, '_blank')}}>Twitter</div>
                        <div className={classes.item} onClick={()=> { event('clicked_instagram'); window.open(config.link.instagram, '_blank')}}>Instagram</div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}