import moment from 'moment'
import SuperEvent from './SuperEvent'

export default class MintEvent extends SuperEvent {

    to!: string
    cve!: string
    transactionHash!: string
    createdAt!: string

    static type: 'NftMinted'

    constructor(event: any){
        super(event)
        this.to = event.returnValues.to
        this.cve = event.returnValues.cve
        this.transactionHash = event.transactionHash
        this.createdAt = moment(new Date(event.createdAt.seconds*1000).toLocaleDateString()).format("DD MMM YYYY")
    }
}