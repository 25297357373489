import { Component } from 'react'
import Container from '@material-ui/core/Container';
import NftCardRow from '../ui/card/NftCardRow'
import NFT from '../../services/NFT'
import { NftType } from '../../interfaces'
import PageHeaderSearchBar from '../ui/PageHeaderSearchBar'
import { MarketplaceGreeting, MarketplaceTutorial, GoToBetaVersion } from './components'

type Props = {}

type State = {
    topSellingTokens: Array<NftType>
    tokensForSale: Array<NftType>
    newlyMintedTokens: Array<NftType>
    uniqueTokens: Array<NftType>
}

export default class Marketplace extends Component<Props, State>  {

    constructor(props:Props){
        super(props)
        this.state = {
            topSellingTokens: [],
            tokensForSale: [],
            newlyMintedTokens: [],
            uniqueTokens: []
        }
    }
    componentDidMount(){
        this.fetchTokensFromFirebase()
    }

    fetchTokensFromFirebase = async () => {

        const topSellingTokens = await NFT.getTopSellingTokens()
        const tokensForSale = await NFT.getTokensForSale()
        const newlyMintedTokens = await NFT.getNewlyMintedTokens()
        const uniqueTokens = await NFT.getUniqueTokens()

        this.setState({
            topSellingTokens: topSellingTokens,
            tokensForSale: tokensForSale,
            newlyMintedTokens: newlyMintedTokens,
            uniqueTokens: uniqueTokens
        })
    }

    render(){

        return (<Container>
            <MarketplaceGreeting/>
            <MarketplaceTutorial/>
            <GoToBetaVersion/>
            <PageHeaderSearchBar 
                title="Marketplace" 
                placeholder="Search CVE to mint"/>
            <br/>
            <NftCardRow title="Newly minted ✨" tokens={this.state.newlyMintedTokens} viewAllPath="new"/>
            <NftCardRow title="Opportunities 🔥" tokens={this.state.tokensForSale} viewAllPath="sales"/>
            {this.state.topSellingTokens.length > 0 && <NftCardRow title="Top sellers 🏆" tokens={this.state.topSellingTokens} viewAllPath="topsellers"/>}
        </Container>)
    }
}