import { SyntheticEvent, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { NotificationContext } from '../../contexts/NotificationContext'
import { colors } from '../../assets/config'

export const PageTitle = ({ title = '' } : { title? : string }) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                fontFamily: 'Kanit',
                fontSize: 24,
                color: 'white'
            }
    }))

    const classes = useStyles();

    const getPageTitle = () => {
        if(window.location.pathname.includes('topsellers')){
            return 'Top sellers 🔥'
        } else  if(window.location.pathname.includes('sales')){
          return 'Opportunities 🔥'
        } if(window.location.pathname.includes('new')){
            return 'Newly minted ✨'
        } if(window.location.pathname.includes('unique')){
            return 'Unique mints ❄️'
        } else {
            return ''
        }
    }
    return <div className={classes.root}>&nbsp;&nbsp;&nbsp;{title != '' ? title : getPageTitle()}</div>
}

export const Eth = () => {
    return <div style={{fontFamily: 'helvetica', float: 'left'}}>Ξ</div>
}


interface BackButtonProps {
  title?: string
}

export const BackButton = ({ title = '' } : BackButtonProps ) => {

  const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

  const useStyles = makeStyles((theme: Theme) =>
      createStyles({
          root: {
              float: 'left',
              marginLeft: xs ? 18 : 0
          },
          button: {
              height: 40,
              paddingRight: -20,
              borderColor: colors.color2,
              borderWidth: 2,
              borderRadius: 10,
              float: 'left'
          },
          icon: {
              fontSize: '18px',
              color: colors.color4,
              marginRight: -10,
          },
          title: {
              display: title != '' ? 'true' : 'none',
              fontSize: '14px',
              fontFamily: 'Biryani',
              fontStyle: 'normal',
              textTransform: 'none',
              color: colors.color4,
              marginTop: 2,
              marginLeft: 10,

          }
  }))
  const history = useHistory()
  const classes = useStyles()
  return (
      <div className={classes.root}>
      <Button 
      onClick={()=> history.goBack()}
      className={classes.button} 
      size="large"
      variant="outlined" 
      color="primary">
          <ArrowBackIos className={classes.icon} />
          <div className={classes.title}>{title}</div>
      </Button>
      </div>
  )
}

// export const Alert = ({ isOpen,  title } : { isOpen : boolean, title: string }) => {
//     const [open, setOpen] = useState(isOpen);
  
//     const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {
//       if (reason === 'clickaway') {
//         setOpen(false);
//       }
//     }
  
//     return (
//         <Snackbar
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//         open={open}
//         autoHideDuration={10000}
//         onClose={handleClose}
//         message={title ?? 'Oops'}
//         action={
//           <Fragment>
//             <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
//               <CloseIcon fontSize="small" />
//             </IconButton>
//           </Fragment>
//         }
//       />
//     )
//   }


  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  
  export function Notification() {
    const classes = useStyles()
    const notification = useContext(NotificationContext)
    const [open, setOpen] = useState(notification != null)

    const handleClick = () => {
      setOpen(true);
    }
  
    const handleClose = (event?: SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    return (
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {notification ? notification : ''}
          </Alert>
        </Snackbar>
      </div>
    );
  }

interface OutlinedIconButtonProps {
  title: string
  handleClick: () => void
  icon?: any
  image?: string
  color?: string
}

export const OutlinedIconButton = ({ title, handleClick, icon, image, color } : OutlinedIconButtonProps) => {
  const useStyles = makeStyles({
      root: {
          width: '100%',
          float: 'left',
          marginTop: 20,
      },
      button: {
          border: `2px solid ${color ?? colors.color3}`,
          color: color ?? colors.color3,
          boxShadow: 'bold',
          borderRadius: 8,
          textTransform: 'none',
          fontFamily: 'Biryani',
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 7
      },
  })
  const classes = useStyles();
  return (
      <div className={classes.root}>
          <Button className={classes.button} size="medium" onClick={()=> handleClick()}>
              {title}&nbsp;&nbsp;&nbsp;{icon ? icon : <img src={image}/>}
          </Button> 
      </div>
  )
}

interface OutlinedColorButtonProps {
  title: string
  handleClick?: (any)
  disabled?: boolean
}

export const OutlinedColorButton = ({ handleClick, title, disabled = false } : OutlinedColorButtonProps) => {
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
          border: `2px solid ${disabled ? 'rgba(0, 0, 0, 0.26)' : colors.theme}`,
          color: colors.theme,
          boxShadow: 'bold',
          borderRadius: 8,
          textTransform: 'none',
          fontFamily: 'Biryani',
          height: 40,
          fontSize: 13,
          paddingTop: 10
      }
  }))
  const classes = useStyles();
  return  <Button 
              onClick={handleClick}
              className={classes.root}
              size="large"
              disabled={disabled}>
            &nbsp;{title}&nbsp;
        </Button>
}


export const Tags = ({tags} : {tags : Array<string>}) => {

  const useStyles = makeStyles((theme: Theme) =>
      createStyles({
          root: {
              width: '100%',
              marginTop: 30,
          },
          tag: {
              fontFamily: 'VT323',
              fontSize: 18,
              border: `2px solid ${colors.color3}`,
              color: colors.color3,
              float: 'left',
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: 17,
              marginRight: 10,
              marginTop: 10
          }
      }))
  const classes = useStyles();
  return tags != null && tags.length != 0 ? (
      <div className={classes.root}>
         {tags.map((tag, i) => (
             <div key={i} className={classes.tag}>{tag}</div>
         ))}
      </div>
  ) : null
}