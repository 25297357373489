import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { NftCardSmall, NftCardSmallPlaceholder } from './NftCardSmall'
import { NftType } from '../../../interfaces'
const { colors } = require('../../../assets/config')

interface NftCardRowProps {
    title: string
    viewAllPath: string
    tokens: Array<NftType>
}

export default function NftCardRow({ tokens, title, viewAllPath } : NftCardRowProps){
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // overflow: 'hidden',
            // msOverflowStyle: 'none',  /* IE and Edge */
            // scrollbarWidth: 'none',  /* Firefox */
        },
        scrollView: {
            height: '280px',
            whiteSpace: 'nowrap',
            position: 'relative',
            overflowX: 'scroll',
            overflow: 'hidden',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            webkitOverflowScrolling: 'touch',
            marginTop: 30,
            marginLeft: 20
        },
        cardContainer: {
            float: 'none',
            marginRight: 15,
            display: 'inline-block',
        }
    }))
    const classes = useStyles()
    
    const NftCards = () => {
        return (<>
            {tokens.map((token, i) => (
                <div key={i} className={classes.cardContainer}>
                    <NftCardSmall token={token}/>
                </div>
            ))} </>
            )
        }
        
        const NftCardsPlaceholders = () => {
            return (<>
            {[0,1,2,3,4,5].map((_, i) => (
                <div key={i} className={classes.cardContainer}>
                    <NftCardSmallPlaceholder index={i} key={i}/>
                </div>
            ))} </>
            )
        }
        
        return (
            <div className={classes.root}>
            <NftRowTitle viewAllPath={viewAllPath} title={title}/>
            <br/>
            <div className={classes.scrollView}>
                {tokens.length > 0 ? <NftCards/> : <NftCardsPlaceholders/>}
            </div>
        </div>
    )
}

const NftRowTitle = ({ title, viewAllPath } : { title : string, viewAllPath: string}) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
               color: 'white',
               fontSize: 20,
               fontFamily: 'Kanit',
               marginLeft: 20
            },
            title: {
                float: 'left',
                marginTop: 5
            }
    }))
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.title}>{title}</div>
            <ViewAllButton path={viewAllPath}/>
        </div>
    )
}

interface ViewAllProps {
    path: string
}

const ViewAllButton = ({ path } : ViewAllProps ) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                float: 'right',
                marginRight: 15
            },
            button: {
                height: 40,
                paddingRight: -20,
                borderColor: colors.color2,
                borderWidth: 2,
                borderRadius: 10,
                float: 'left'
            },
            icon: {
                marginLeft: 10,
                fontSize: '18px',
                color: colors.color4,
                marginRight: -10,
            },
            title: {
                fontSize: '14px',
                fontFamily: 'Biryani',
                fontStyle: 'normal',
                textTransform: 'none',
                color: colors.color4,
                marginTop: 5,
                marginLeft: -5,

            }
    }))
    const history = useHistory()
    const classes = useStyles()
    return (
        <div className={classes.root}>
        <Button 
        onClick={()=> history.push(path)}
        className={classes.button} 
        size="large"
        variant="outlined" 
        color="primary">
            <div className={classes.title}>View more</div>
            <ArrowForwardIos className={classes.icon} />
        </Button>
        </div>
    )
}