import { getAccount } from './Web3'
import { UserType } from '../interfaces'
import { signNonce } from './Web3'
import { UserSignatureError } from '../assets/errors.json'
const { auth, signInWithCustomToken, db, collection, query, where, getDoc, doc, getDocs } = require('../assets/firebase')
const { config } = require('../assets/config')

export default class User {

    account!: string
    
    constructor(){}

    static doc = 'Users'

    static getUser = async (account: string) => {
        let token
        // if(User.isLoggedIn()){
        //     token = auth.currentUser.getIdToken()
        //     console.log("TOKEN: ",token)
        // }
        const response = await fetch(config.url.api+config.url.user+"/"+account+"?token="+token)
        const json = await response.json()
        if(json.error){return undefined}
        return json as UserType
    }

    static getCurrentUser = async () => {
        const account = await getAccount()
        if(account == '') return undefined
        const response = await fetch(config.url.api+config.url.signIn+"/"+account)
        const user = await response.json() as UserType
        return user.nonce ? user : undefined
    }

    static authentication = async (signature: string) => {
        const account = await getAccount()
        if(account == '') return undefined
        const response = await fetch(config.url.api+config.url.authentication+"?account="+account+"&signature="+signature)
        return await response.json()
    }

    static signIn = async (success: (user: UserType) => (void), fail: (message: string)=>(void)) => {
       
        // Get or create User in backend
        const currentUser = await User.getCurrentUser()
        if(!currentUser){return fail('login_error')}

        // Ask user to sign nonce message
        const signature = await signNonce(`${config.nonceMessage}${currentUser.nonce}`)
        if(signature?.code == 4001){return} //'MetaMask Message Signature: User denied message signature.'
        const response = await User.authentication(signature)
        
        // Handle errors
        if(!response?.token){
            switch(response!.error){
                case UserSignatureError :
                    return fail('invalid_signature')
                default :
                    console.error("ERROR: ",response!.error)
                    return fail('invalid_signature')
            }
        }

        // Firebase login
        console.log(response.token)
        const userCredential = await signInWithCustomToken(auth, response.token)
        if(userCredential.user){
            success(currentUser)
        } else {
            fail('login_error')
        }
    }

    static signOut = () => auth.signOut()

    static isLoggedIn = () => auth.currentUser != undefined

}

