import { useState, useEffect } from 'react'
import moment from 'moment'
import EditIcon from '@material-ui/icons/Settings';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getShortWalletAddressForProfile } from '../../hooks/miscHooks'
import { Eth } from '../ui/components'
import { getEthBalance, getAccount } from '../../services/Web3'
import Token from '../../services/Token'
import { UserType } from '../../interfaces'
import { colors } from '../../assets/config'
import ProfileSettingsDialog from '../ui/dialogs/ProfileSettingsDialog';

export default function User({ user, isLoggedIn } : { user?: UserType, isLoggedIn: boolean }){

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                background: colors.color2,
                width: 313,
                height: isLoggedIn ? 418 : 270,
                borderRadius: 8,
                textAlign: 'center',
                float: 'left'
            },
            title: {
                fontFamily: 'Kanit',
                fontSize: 34,
                color: 'white',
                marginTop: xs ? 30 : 60
            },
            settingsButton: {
                float: 'right',
                color: colors.color3,
                padding: 12,
                cursor: 'pointer'
            }
    }))

    const classes = useStyles();

    const Avatar = () => {
        const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            imageContainer: {
                width: 132,
                height: 132,
                margin: 'auto',
            },
            image: {
                marginTop: 22,
                width: 132,
                height: 132,
                borderRadius: 66,
                background: colors.color3,
                padding: 20
            }
    }))

    const classes = useStyles();
        return (
            <div className={classes.imageContainer}>
                <img src={'/images/avatar.png'} className={classes.image}/>
            </div>
        )
    }

    const AccountName = () => {
        const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                fontFamily: 'Kanit',
                fontSize: 18,
                color: 'white',
                marginTop: 40
            }
        }))

        const classes = useStyles();
        
        
        const Whale = () => {
            
            const [balance, setBalance] = useState(0)
            useEffect(() => {
                (async () => {
                    let _balance = await Token.getBalance(user!.account)
                    setBalance(parseInt(_balance))
                })()
            })
            return <>&nbsp;{balance >= 25 ? '🐋' : ''}</>
        }

        return user ? (
            <div className={classes.root}>
                {getShortWalletAddressForProfile(user!.account)}
                <Whale/>
            </div>
        ) : null
    }

    const Bio = () => {
        const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                fontFamily: 'VT323',
                fontSize: 18,
                color: colors.color4,
                marginTop: 10
            }
        }))

        const classes = useStyles();
        return user ? (
            <div className={classes.root}>Member since {moment(new Date(user.createdAt._seconds*1000).toLocaleDateString()).format("MMMM YYYY")}</div>
        ) : null
    }

    const Balances = () => {

        const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
               marginTop: 30,
               textAlign: 'left'
            }
      })
    )

    const classes = useStyles()
    const [loggedInAccount, setLoggedInAccount] = useState('')

    useEffect(()=> {
        (async () => {
         setLoggedInAccount(await getAccount())
       })()
     },[])

    return user && user.account == loggedInAccount ? (
        <div className={classes.root}>
            <EthBalanceRow/>
            <CveTokenBalanceRow/>
        </div>
    ) : null
    }

    const EthBalanceRow = () => {

        const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                background: colors.color1,
                borderRadius: 5,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 10,
                height: 64,
                padding: 3,
                color: 'white'
            },
            button: {
                height: 40,
                width: 40,
                borderRadius: 5,
                background: colors.color2,
                margin: 9,
                padding: 5,
                textAlign: 'center',
                fontSize: 25,
                float: 'left'
            },
            icon: {
                float: 'left',
                width: 30,
                marginTop: 13
            },
            title: {
                fontFamily: 'Kanit',
                fontWeight: 'bold',
                fontSize: 18,
                marginTop: 5,
            },
            subtitle: {
                fontFamily: 'Biryani',
                fontSize: 13,
            }
      })
    )
    const classes = useStyles()
    const [balance, setBalance] = useState('...')

    useEffect(() => {
        (async () => {
            let _balance = await getEthBalance()
            _balance = _balance.substring(0,8)
            setBalance(_balance)
        })()
    },[])

        return (
            <div className={classes.root} onClick={()=>{}}>
                <img src="/images/ether-white.png" className={classes.button}/>
                <div className={classes.title}>Ether</div>
                <div className={classes.subtitle}><Eth/>&nbsp;{balance}</div>
            </div>
        )
    }

    const CveTokenBalanceRow = () => {

        const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                background: colors.color1,
                borderRadius: 5,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 10,
                height: 64,
                padding: 3,
                color: 'white'
            },
            button: {
                height: 40,
                width: 40,
                borderRadius: 5,
                background: colors.color2,
                margin: 9,
                padding: 5,
                textAlign: 'center',
                fontSize: 25,
                float: 'left'
            },
            icon: {
                float: 'left',
                width: 30,
                marginTop: 13
            },
            title: {
                fontFamily: 'Kanit',
                fontWeight: 'bold',
                fontSize: 18,
                marginTop: 5,
            },
            subtitle: {
                fontFamily: 'Biryani',
                fontSize: 13,
            }
      })
    )
    const classes = useStyles()
    const [balance, setBalance] = useState('...')

    useEffect(() => {
        (async () => {
            let _balance = await Token.getBalance()
            setBalance(_balance)
        })()
    })

        return (
            <div className={classes.root} onClick={()=>{}}>
                <img src="/images/logo-small-white.png" className={classes.button}/>
                <div className={classes.title}>CVE Token</div>
                <div className={classes.subtitle}>{balance}</div>
            </div>
        )
    }

    return (
        <>
        {/* {user && <ProfileSettingsDialog user={user} open={true} onConfirm={()=> undefined} onClose={()=> undefined}/>} */}
        <div className={classes.title}></div>
        <div className={classes.root}>
            {/* {isLoggedIn && <div className={classes.settingsButton}>
                <EditIcon/>
            </div>} */}
            <Avatar/>
            <AccountName/>
            <Bio/>
            <Balances/>
        </div>
        </>
    )
}