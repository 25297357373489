import config from './config.json';

switch(config.environment){
    case "rinkeby" :
        config.url.api = config.url.rinkeby.api
        break 
    case "mainnet" :
        config.url.api = config.url.mainnet.api
        break 
    default :
        config.url.api = config.url.development.api
}

const colors = config.colors

export {
    config,
    colors
}