import { createContext } from 'react';
import { UserType } from '../interfaces'

interface AccountContextType {
    user: UserType | undefined;
    setUser: (user: UserType) => void;
}

const defaultValue = {
  user: undefined,
}

const AccountContext = createContext<Partial<AccountContextType>>(defaultValue);

export default AccountContext;