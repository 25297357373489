import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import { OutlinedIconButton } from '../ui/components'
import { colors, config } from '../../assets/config'

export const MarketplaceGreeting = () => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                marginTop: 50,
                textAlign: 'center',
                color: 'white'
            },
            logo: {
                width: 139
            },
            title: {
                marginTop: 10,
                fontFamily: 'Kanit',
                fontSize: 45,
                lineHeight: 1
            },
            text: {
                marginTop: 25,
                marginBottom: 25,
                fontFamily: 'Biryani',
                fontSize: 16,
                paddingLeft: xs || sm  ? 0 : '20%',
                paddingRight: xs || sm  ? 0 : '20%',
            },
            shadeLogo: {
                position: 'absolute',
                width: 300,
                marginTop: -120,
                marginLeft: -30,
                zIndex: -100
            }
    }))
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img className={classes.shadeLogo} src={'/images/shade.svg'}/>
            <img className={classes.logo} src={'/images/logo2.svg'}/>
            <div className={classes.title}>Welcome to Crypto CVE’s</div>
            <div className={classes.text}>
                A CVE is a software vulnerability in the cybersecurity world. Here you can mint famous CVE’s as collectibles, hold or trade with others.
            </div>
        </div>
    )
}


export const MarketplaceTutorial = () => {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
               color: 'white',
            }
    }))

    const classes = useStyles();

    return (
        <Grid container className={classes.root} justifyContent={'center'}>
            <Grid container justifyContent={'center'}>
                <MarketplaceTutorialStep image={'/images/tutorial-step1.svg'} step="STEP 1" title="Connect with wallet" subtitle="We are supporting most wallets out there" arrow={true}/>
                <MarketplaceTutorialStep image={'/images/tutorial-step2.svg'} step="STEP 2" title="Search for CVE's" subtitle="Using the Mitre API of all CVE's ever submitted" arrow={true}/>
                <MarketplaceTutorialStep image={'/images/tutorial-step3.svg'} step="STEP 3" title="Mint unclaimed CVE's" subtitle="150,000+ in circulation already, with more being found daily" arrow={true}/>
                <MarketplaceTutorialStep image={'/images/tutorial-step4.svg'} step="STEP 4" title="Trade with others, or hold!" subtitle="Hold long term or sell to others - the choice is yours"/>
            </Grid>
        </Grid>)
}


export const GoToBetaVersion = () => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                marginTop: 50,
                marginBottom: 50,
                textAlign: 'center',
                color: 'white',
            },
            text: {
                marginTop: 25,
                marginBottom: 10,
                fontFamily: 'Biryani',
                fontSize: 16,
                paddingLeft: xs || sm ? 0 : '25%',
                paddingRight: xs || sm ? 0 : '25%'
            },
            themed: {
                color: colors.theme,
                pointerEvents: 'none',
                textDecoration: 'none'
            },
            buttonContainer: {
                minWidth: 220,
            },
            space: {
                marginBottom: 60
            }
    }))
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className={classes.root}>
            <div className={classes.text}>
                We are live on <a className={classes.themed}>Arbitrum</a>, an Ethereum layer 2 blockchain where you can mint and trade with smaller fees than Ethereum layer 1 by bridging your eth.
            </div>
            <Grid container justifyContent={'center'}>
                <Grid container justifyContent={'center'}>
                    <div className={classes.buttonContainer}>
                        <OutlinedIconButton title="Join our Discord" image="/images/discord.svg" color={colors.color4}  handleClick={()=> window.open(config.link.discord, '_blank')}/>
                    </div>
                    <div className={classes.buttonContainer}>
                        <OutlinedIconButton title="How to mint" icon={<ArrowForwardIos fontSize="small"/>} color={colors.color4} handleClick={()=> history.push('/tutorial')}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}




// Used only in MarketplaceTutorial
const MarketplaceTutorialStep = ({ image, step, title, subtitle, arrow = false } : { image: string, step: string, title: string, subtitle: string, arrow?: boolean }) => {

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)');

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 228,
            textAlign: 'center',
            margin: 10
        },
        image: {

        },
        step: {
            marginTop: 10,
            fontFamily: 'Kanit',
            fontSize: 13,
            color: colors.theme
        },
        title: {
            marginTop: 5,
            fontFamily: 'Biryani',
            fontSize: 13,
        },
        subtitle: {
            marginTop: 5,
            fontFamily: 'Biryani',
            fontSize: 13,
            color: colors.color3,
            paddingLeft: 10,
            paddingRight: 10,
        },
        arrow: {
            display: xs ? 'none' : 'true',
            position: 'absolute',
            marginLeft: 225,
            marginTop: 85,
            width: 26
        }
    }))
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {arrow && <img className={classes.arrow} src="/images/tutorial-arrow-right.svg"/>}
            <img className={classes.image} src={image}/>
            <div className={classes.step}>{step}</div>
            <div className={classes.title}>{title}</div>
            <div className={classes.subtitle}>{subtitle}</div>
        </div>
    )
}