import axios from 'axios'
import { getWeb3, getAccount, getNetworkId } from './Web3'
import Marketplace from './Marketplace'
import { NftType } from '../interfaces'
const json = require('../contracts/NFT.json');
const { db, collection, query, limit, orderBy, getDocs, where } = require('../assets/firebase')
const { config } = require('../assets/config');
const { Web3UnavailableError } = require('../assets/errors');
const convert = require('ether-converter')
const networkId = getNetworkId()


export default class NFT {

    static contract: any
    static doc = 'CVE'

    constructor(){}

    static initContractIfNeeded = async () => {
        if(NFT.contract){return}
        const web3 = await getWeb3()
        if(!web3){throw Web3UnavailableError}

        NFT.contract = await new web3.eth.Contract(
            json.abi,
            json.networks[networkId].address
        )
    }

    static getTokenByIdFromBlockchain = async (tokenId: number) => {
        await NFT.initContractIfNeeded()
        const token = await NFT.contract.methods.getById(tokenId).call()
        return token
    }

    static getTokenFromApiByTokenId = async (tokenId: number) => {
        const response = await fetch(config.url.api+config.url.nft+"?tokenId="+tokenId)
        console.log(response)
        return await response.json()
    }

    static getTokenFromApiByCve = async (cve: string) => {
        const response = await fetch(config.url.api+config.url.nft+"?cve="+cve)
        console.log(response)
        return await response.json()
    }

    static getPreview = async (cve: string) => {
        const response = await fetch(config.url.api+config.url.preview+"?cve="+cve)
        return await response.json()
    }

    static getNextTokenIdFromBlockchain = async () => {
        await NFT.initContractIfNeeded()
        const nextId = await NFT.contract.methods.nextTokenId().call()
        return (parseInt(nextId)+1) ?? '...'
    }
    
    // static getTokensForAccountFromBlockchain = async () => {
    //     await NFT.initContractIfNeeded()
    //     const address = await getAccount()
    //     const tokens = await NFT.contract.methods.balanceOf(address).call()
    //     const result = []
    //     for(var i = 0; i < tokens.length; i++){result.push({
    //         id: tokens[i],
    //         loading: true
    //     })}
    //     return result.reverse()
    // }

    static getTokensForAccount = async (address: string) => {
        
        var docs = await collection(db, NFT.doc)
        const q = query(docs, where("owner", "==", address), orderBy('createdAt','desc')) // NEEDS A BETTER WAY!
        const querySnapshot = await getDocs(q)
        
        const tokens = Array()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => {
            tokens.push(doc.data())
        })
        return tokens
    }
    

    static getTopSellingTokens = async (_limit: number = 30) => {
        var docs = await collection(db, NFT.doc)
        const q = query(docs, orderBy("latestSale"), where('status','==','minted'), limit(_limit)) // NEEDS A BETTER WAY!
        const querySnapshot = await getDocs(q)

        const tokens = Array<NftType>()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => tokens.push(doc.data()))
        return tokens.sort((a, b) => {
            const A = convert(a.latestSale!, 'wei').ether
            const B = convert(b.latestSale!, 'wei').ether
            return parseFloat(A) - parseFloat(B)
        }).reverse()
    }

    static getTokensForSale = async (_limit: number = 30) => {
        var docs = await collection(db, NFT.doc)
        const q = query(docs, orderBy('price','asc'), orderBy('createdAt','desc'), where('price','!=', null), limit(_limit))
        const querySnapshot = await getDocs(q)

        var tokens = Array<NftType>()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => tokens.push(doc.data()))
        tokens.sort((a, b) => {
            const A = convert(a.price!, 'wei').ether
            const B = convert(b.price!, 'wei').ether
            return parseFloat(A) - parseFloat(B)
        })
        return tokens
    }

    static getNewlyMintedTokens = async (_limit: number = 30) => {
        var docs = await collection(db, NFT.doc)
        const q = query(docs, orderBy('createdAt','desc'), where('status','==','minted'), limit(_limit))
        const querySnapshot = await getDocs(q)

        const tokens = Array<NftType>()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => tokens.push(doc.data()))
        return tokens
    }

    static getUniqueTokens = async (_limit: number = 30) => {
        var docs = await collection(db, NFT.doc)
        const q = query(docs, orderBy('createdAt','asc'), where('status','==','minted'), limit(_limit))
        const querySnapshot = await getDocs(q)

        const tokens = Array<NftType>()
        await querySnapshot.forEach((doc: { data: () => any; id: any }) => tokens.push(doc.data()))
        return tokens
    }

    static getContractAddress = async () => {
        try {
            return json.networks[networkId].address
        } catch(error){
            // console.error(error)
            return undefined
        }
    }

    static approve = async (address: string, tokenId: number) => {
        await NFT.initContractIfNeeded()
        const owner = await getAccount()
        const response = await NFT.contract.methods.approve(address, tokenId).send({ from: owner })
        return response.transactionHash != undefined
    }

    static getApproved = async (tokenId: number) => {
        await NFT.initContractIfNeeded()
        return NFT.contract.methods.getApproved(tokenId).call()
    }

    static getOwner = async (tokenId: number) => {
        await NFT.initContractIfNeeded()
        return NFT.contract.methods.ownerOf(tokenId).call()
    }

    static isOwnedByMe = async (tokenId: number) => {
        await NFT.initContractIfNeeded()
        const owner = await NFT.contract.methods.ownerOf(tokenId).call()
        const address = await getAccount()
        return owner == address
    }

    static isMarketplaceApproved = async (tokenId: number) => {
        await NFT.initContractIfNeeded()
        const marketplaceAddress = await Marketplace.getContractAddress()
        const approved = await NFT.getApproved(tokenId)
        return marketplaceAddress == approved
    }

    static sync = async (cve: string) => axios.post(config.url.api+config.url.syncNft+"?cve="+cve)

    static sendEvent = (event: any) => axios.post(config.url.api+config.url.event, { event: event })
}
